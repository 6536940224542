<template>
  <div>
    <div
      v-if="showStyling"
      class="is-hidden-print actionBar has-background-white-ter has-padding has-margin-bottom"
    >
      <span class="level-right">
        <div class="level-right has-margin-right">
          <div class="level-left"></div>
          <div class="level-right">
            <a @click="resetStyling">
              <span class="icon">
                <font-awesome-icon
                  :icon="['fas', 'times']"
                  class="has-text-info"
                />
              </span>
              <span>Reset styling</span>
            </a>
          </div>
        </div>
        <div class="level-right">
          <div class="level-left"></div>
          <div class="level-right">
            <a @click="saveStyling">
              <span class="icon">
                <font-awesome-icon
                  :icon="['fas', 'check']"
                  class="has-text-info"
                />
              </span>
              <span>Save styling</span>
            </a>
          </div>
        </div>
      </span>
    </div>

    <ui-page-hero
      id="id_title_widget_settings"
      :title="
        $t('Components.Settings.Widgets.bookingsWidgetStyling.Main_Title')
      "
    ></ui-page-hero>

    <div v-if="mWidget === null">
      <ui-loader />
    </div>

    <div v-else>
      <ui-section-header class="has-margin-top-x2">
        <template v-slot:title>{{
          $t('Components.Settings.Widgets.bookingsWidgetStyling.Subtitle_Name')
        }}</template>
      </ui-section-header>
      <input
        v-model="mWidget.Name"
        class="column is-5 input"
        @change="saveWidget"
      />

      <ui-section-header class="has-margin-top-x2">
        <template v-slot:title>{{
          $t(
            'Components.Settings.Widgets.bookingsWidgetStyling.Subtitle_Options'
          )
        }}</template>
      </ui-section-header>

      <div class="columns has-margin-bottom-x2 has-margin-top">
        <div class="column">
          <table class="table is-fullwidth is-striped is-hoverable">
            <tbody>
              <tr>
                <td>
                  {{
                    $t(
                      'Components.Settings.Widgets.bookingsWidgetStyling.Label_ShowCountrySelect'
                    )
                  }}
                </td>
                <td>
                  <div class="tabs is-toggle is-small">
                    <ul>
                      <li :class="{ 'is-active': mWidget.ShowCountrySelect }">
                        <a
                          @click="
                            ;(mWidget.ShowCountrySelect = true), saveWidget()
                          "
                        >
                          <span>{{
                            $t(
                              'Components.Settings.Widgets.bookingsWidgetStyling.Label_Yes'
                            )
                          }}</span>
                        </a>
                      </li>
                      <li :class="{ 'is-active': !mWidget.ShowCountrySelect }">
                        <a
                          @click="
                            ;(mWidget.ShowCountrySelect = false), saveWidget()
                          "
                        >
                          <span>{{
                            $t(
                              'Components.Settings.Widgets.bookingsWidgetStyling.Label_No'
                            )
                          }}</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  {{
                    $t(
                      'Components.Settings.Widgets.bookingsWidgetStyling.Label_ShowSearchInput'
                    )
                  }}
                </td>
                <td>
                  <div class="tabs is-toggle is-small">
                    <ul>
                      <li :class="{ 'is-active': mWidget.ShowSearchInput }">
                        <a
                          @click="
                            ;(mWidget.ShowSearchInput = true), saveWidget()
                          "
                        >
                          <span>{{
                            $t(
                              'Components.Settings.Widgets.bookingsWidgetStyling.Label_Yes'
                            )
                          }}</span>
                        </a>
                      </li>
                      <li :class="{ 'is-active': !mWidget.ShowSearchInput }">
                        <a
                          @click="
                            ;(mWidget.ShowSearchInput = false), saveWidget()
                          "
                        >
                          <span>{{
                            $t(
                              'Components.Settings.Widgets.bookingsWidgetStyling.Label_No'
                            )
                          }}</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  {{
                    $t(
                      'Components.Settings.Widgets.bookingsWidgetStyling.Label_ShowMeetingTypeSelection'
                    )
                  }}
                </td>
                <td>
                  <div class="tabs is-toggle is-small">
                    <ul>
                      <li
                        :class="{ 'is-active': mWidget.ShowMeetingtypeSelect }"
                      >
                        <a
                          @click="
                            ;(mWidget.ShowMeetingtypeSelect = true),
                              saveWidget()
                          "
                        >
                          <span>{{
                            $t(
                              'Components.Settings.Widgets.bookingsWidgetStyling.Label_Yes'
                            )
                          }}</span>
                        </a>
                      </li>
                      <li
                        :class="{ 'is-active': !mWidget.ShowMeetingtypeSelect }"
                      >
                        <a
                          @click="
                            ;(mWidget.ShowMeetingtypeSelect = false),
                              saveWidget()
                          "
                        >
                          <span>{{
                            $t(
                              'Components.Settings.Widgets.bookingsWidgetStyling.Label_No'
                            )
                          }}</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="column">
          <table class="table is-fullwidth is-striped is-hoverable">
            <tbody>
              <tr>
                <td>
                  {{
                    $t(
                      'Components.Settings.Widgets.bookingsWidgetStyling.Label_DefaultMeetingType'
                    )
                  }}
                </td>
                <td>
                  <div class="tabs is-toggle is-small">
                    <ul>
                      <li
                        :class="{
                          'is-active': mWidget.PreSelectedMeetingtype === 0,
                        }"
                      >
                        <a
                          @click="
                            ;(mWidget.PreSelectedMeetingtype = 0), saveWidget()
                          "
                        >
                          <span>{{
                            $t(
                              'Components.Settings.Widgets.bookingsWidgetStyling.Label_None'
                            )
                          }}</span>
                        </a>
                      </li>
                      <li
                        v-for="(meetingtype, index) in channel.Meetingtypes"
                        :key="index"
                        :class="{
                          'is-active':
                            mWidget.PreSelectedMeetingtype ===
                            meetingtype.MeetingtypeId,
                        }"
                      >
                        <a
                          @click="
                            ;(mWidget.PreSelectedMeetingtype =
                              meetingtype.MeetingtypeId),
                              saveWidget()
                          "
                        >
                          <span>{{
                            $options.filters.getMeetingtypeName(
                              meetingtype.MeetingtypeId
                            )
                          }}</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  {{
                    $t(
                      'Components.Settings.Widgets.bookingsWidgetStyling.Label_ShowOptionsStep'
                    )
                  }}
                </td>
                <td>
                  <div class="tabs is-toggle is-small">
                    <ul>
                      <li :class="{ 'is-active': mWidget.ShowOptionsStep }">
                        <a
                          @click="
                            ;(mWidget.ShowOptionsStep = true), saveWidget()
                          "
                        >
                          <span>{{
                            $t(
                              'Components.Settings.Widgets.bookingsWidgetStyling.Label_Yes'
                            )
                          }}</span>
                        </a>
                      </li>
                      <li :class="{ 'is-active': !mWidget.ShowOptionsStep }">
                        <a
                          @click="
                            ;(mWidget.ShowOptionsStep = false), saveWidget()
                          "
                        >
                          <span>{{
                            $t(
                              'Components.Settings.Widgets.bookingsWidgetStyling.Label_No'
                            )
                          }}</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  {{
                    $t(
                      'Components.Settings.Widgets.bookingsWidgetStyling.Label_ShowBookingDetailsStep'
                    )
                  }}
                </td>
                <td>
                  <div class="tabs is-toggle is-small">
                    <ul>
                      <li
                        :class="{ 'is-active': mWidget.ShowBookingDetailsStep }"
                      >
                        <a
                          @click="
                            ;(mWidget.ShowBookingDetailsStep = true),
                              saveWidget()
                          "
                        >
                          <span>{{
                            $t(
                              'Components.Settings.Widgets.bookingsWidgetStyling.Label_Yes'
                            )
                          }}</span>
                        </a>
                      </li>
                      <li
                        :class="{
                          'is-active': !mWidget.ShowBookingDetailsStep,
                        }"
                      >
                        <a
                          @click="
                            ;(mWidget.ShowBookingDetailsStep = false),
                              saveWidget()
                          "
                        >
                          <span>{{
                            $t(
                              'Components.Settings.Widgets.bookingsWidgetStyling.Label_No'
                            )
                          }}</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div v-if="showStyling">
        <ui-section-header class="has-margin-top-x2">
          <template v-slot:title>Style your widget </template>
        </ui-section-header>

        <div>
          <div class="columns has-margin-top has-margin-bottom-x2">
            <div class="column is-one-fifth">
              <table class="table is-fullwidth is-borderless is-hoverable">
                <tr class="has-background-light">
                  <td>
                    <div class="level">
                      <a @click="showStylingOptions = 1" class="level-left"
                        >General</a
                      >
                      <a v-if="showStylingOptions === 1">
                        <font-awesome-icon
                          @click="showStylingOptions = 1"
                          class="level-right"
                          :icon="['fas', 'chevron-down']"
                        />
                      </a>
                      <a v-else-if="showStylingOptions !== 1">
                        <font-awesome-icon
                          @click="showStylingOptions = 1"
                          class="level-right"
                          :icon="['fas', 'chevron-right']"
                        />
                      </a>
                    </div>
                  </td>
                </tr>

                <TransitionExpand>
                  <tr v-if="showStylingOptions === 1" key="borderRadius">
                    <td>
                      <div class="level">
                        Rounding of corners
                        <button
                          class="button level-right"
                          v-on:click="toggleGeneralRadius"
                        >
                          {{ GeneralRadius.values }}
                        </button>
                      </div>
                      <div>
                        <vue-slider
                          class="has-margin-bottom has-margin-right has-margin-left"
                          v-show="GeneralRadius.isOpen"
                          v-model="GeneralRadius.values"
                          :marks="GeneralRadius.marks"
                          :value="GeneralRadius.values"
                          direction="ltr"
                          :min="0"
                          :max="20"
                          :minRange="1"
                          :interval="1"
                        ></vue-slider>
                      </div>
                    </td>
                  </tr>
                </TransitionExpand>

                <TransitionExpand>
                  <tr v-if="showStylingOptions === 1" key="borderColors">
                    <td>
                      <div class="level">
                        Border colors:
                        <button
                          class="button level-right"
                          v-on:click="toggleGeneralBorderColor"
                          v-bind:style="{
                            background: GeneralBorderColor.colors.hex,
                          }"
                        ></button>
                      </div>
                      <div class="level-item">
                        <sketch-picker
                          position="absolute"
                          v-show="GeneralBorderColor.isOpen"
                          v-model="GeneralBorderColor.colors"
                          :value="GeneralBorderColor.colors"
                          :preset-colors="[
                            '#f00',
                            '#00ff00',
                            '#00ff0055',
                            'rgb(201, 76, 76)',
                            'rgba(0,0,255,1)',
                            'hsl(89, 43%, 51%)',
                            'hsla(89, 43%, 51%, 0.6)',
                          ]"
                        ></sketch-picker>
                      </div>
                    </td>
                  </tr>
                </TransitionExpand>

                <TransitionExpand>
                  <tr v-if="showStylingOptions === 1" key="widgetBackground">
                    <td>
                      <div class="level">
                        Main background color:
                        <button
                          class="button level-right"
                          v-on:click="toggleGeneralBackground"
                          v-bind:style="{
                            background: GeneralBackground.colors.hex,
                          }"
                        ></button>
                      </div>
                      <div class="level-item">
                        <sketch-picker
                          position="absolute"
                          v-show="GeneralBackground.isOpen"
                          v-model="GeneralBackground.colors"
                          :value="GeneralBackground.colors"
                          :preset-colors="[
                            '#f00',
                            '#00ff00',
                            '#00ff0055',
                            'rgb(201, 76, 76)',
                            'rgba(0,0,255,1)',
                            'hsl(89, 43%, 51%)',
                            'hsla(89, 43%, 51%, 0.6)',
                          ]"
                        ></sketch-picker>
                      </div>
                    </td>
                  </tr>
                </TransitionExpand>

                <TransitionExpand>
                  <tr v-if="showStylingOptions === 1" key="titleTextColor">
                    <td>
                      <div class="level">
                        Main title text color:
                        <button
                          class="button level-right"
                          v-on:click="toggleGeneralTitleColor"
                          v-bind:style="{
                            background: GeneralTitleColor.colors.hex,
                          }"
                        ></button>
                      </div>
                      <div class="level-item">
                        <sketch-picker
                          position="absolute"
                          v-show="GeneralTitleColor.isOpen"
                          v-model="GeneralTitleColor.colors"
                          :value="GeneralTitleColor.colors"
                          :preset-colors="[
                            '#f00',
                            '#00ff00',
                            '#00ff0055',
                            'rgb(201, 76, 76)',
                            'rgba(0,0,255,1)',
                            'hsl(89, 43%, 51%)',
                            'hsla(89, 43%, 51%, 0.6)',
                          ]"
                        ></sketch-picker>
                      </div>
                    </td>
                  </tr>
                </TransitionExpand>

                <tr class="has-background-light">
                  <td>
                    <div class="level">
                      <a v-on:click="showStylingOptions = 2">Header</a>
                      <a v-if="showStylingOptions === 2">
                        <font-awesome-icon
                          @click="showStylingOptions = 2"
                          class="level-right"
                          :icon="['fas', 'chevron-down']"
                        />
                      </a>
                      <a v-else-if="showStylingOptions !== 2">
                        <font-awesome-icon
                          @click="showStylingOptions = 2"
                          class="level-right"
                          :icon="['fas', 'chevron-right']"
                        />
                      </a>
                    </div>
                  </td>
                </tr>

                <TransitionExpand>
                  <tr v-if="showStylingOptions === 2" key="navBackground">
                    <td>
                      <div class="level">
                        Background color:
                        <button
                          class="button level-right"
                          v-on:click="toggleHeaderBackground"
                          v-bind:style="{
                            background: HeaderBackground.colors.hex,
                          }"
                        ></button>
                      </div>
                      <div class="level-item">
                        <sketch-picker
                          position="absolute"
                          class="picker"
                          v-show="HeaderBackground.isOpen"
                          v-model="HeaderBackground.colors"
                          :value="HeaderBackground.colors"
                          :preset-colors="[
                            '#f00',
                            '#00ff00',
                            '#00ff0055',
                            'rgb(201, 76, 76)',
                            'rgba(0,0,255,1)',
                            'hsl(89, 43%, 51%)',
                            'hsla(89, 43%, 51%, 0.6)',
                          ]"
                        ></sketch-picker>
                      </div>
                    </td>
                  </tr>
                </TransitionExpand>

                <TransitionExpand>
                  <tr
                    v-if="showStylingOptions === 2"
                    key="navTextColorCompletedStep"
                  >
                    <td>
                      <div class="level">
                        Text color completed step:
                        <button
                          class="button level-right"
                          v-on:click="toggleNavCompleted"
                          v-bind:style="{ background: NavCompleted.colors.hex }"
                        ></button>
                      </div>
                      <div class="level-item">
                        <sketch-picker
                          position="absolute"
                          v-show="NavCompleted.isOpen"
                          v-model="NavCompleted.colors"
                          :value="NavCompleted.colors"
                          :preset-colors="[
                            '#f00',
                            '#00ff00',
                            '#00ff0055',
                            'rgb(201, 76, 76)',
                            'rgba(0,0,255,1)',
                            'hsl(89, 43%, 51%)',
                            'hsla(89, 43%, 51%, 0.6)',
                          ]"
                        ></sketch-picker>
                      </div>
                    </td>
                  </tr>
                </TransitionExpand>

                <TransitionExpand>
                  <tr
                    v-if="showStylingOptions === 2"
                    key="navTextColorCurrentStep"
                  >
                    <td>
                      <div class="level">
                        Text color current step:
                        <button
                          class="button level-right"
                          v-on:click="toggleNavCurrent"
                          v-bind:style="{ background: NavCurrent.colors.hex }"
                        ></button>
                      </div>
                      <div class="level-item">
                        <sketch-picker
                          position="absolute"
                          v-show="NavCurrent.isOpen"
                          v-model="NavCurrent.colors"
                          :value="NavCurrent.colors"
                          :preset-colors="[
                            '#f00',
                            '#00ff00',
                            '#00ff0055',
                            'rgb(201, 76, 76)',
                            'rgba(0,0,255,1)',
                            'hsl(89, 43%, 51%)',
                            'hsla(89, 43%, 51%, 0.6)',
                          ]"
                        ></sketch-picker>
                      </div>
                    </td>
                  </tr>
                </TransitionExpand>

                <TransitionExpand>
                  <tr
                    v-if="showStylingOptions === 2"
                    key="navTextColorDisabledStep"
                  >
                    <td>
                      <div class="level">
                        Disabled text color:
                        <button
                          class="button level-right"
                          v-on:click="toggleNavInactive"
                          v-bind:style="{ background: NavInactive.colors.hex }"
                        ></button>
                      </div>
                      <div class="level-item">
                        <sketch-picker
                          position="absolute"
                          v-show="NavInactive.isOpen"
                          v-model="NavInactive.colors"
                          :value="NavInactive.colors"
                          :preset-colors="[
                            '#f00',
                            '#00ff00',
                            '#00ff0055',
                            'rgb(201, 76, 76)',
                            'rgba(0,0,255,1)',
                            'hsl(89, 43%, 51%)',
                            'hsla(89, 43%, 51%, 0.6)',
                          ]"
                        ></sketch-picker>
                      </div>
                    </td>
                  </tr>
                </TransitionExpand>

                <TransitionExpand>
                  <tr v-if="showStylingOptions === 2" key="navArrowsColor">
                    <td>
                      <div class="level">
                        Arrows color:
                        <button
                          class="button level-right"
                          v-on:click="toggleNavArrows"
                          v-bind:style="{ background: NavArrows.colors.hex }"
                        ></button>
                      </div>
                      <div class="level-item">
                        <sketch-picker
                          position="absolute"
                          v-show="NavArrows.isOpen"
                          v-model="NavArrows.colors"
                          :value="NavArrows.colors"
                          :preset-colors="[
                            '#f00',
                            '#00ff00',
                            '#00ff0055',
                            'rgb(201, 76, 76)',
                            'rgba(0,0,255,1)',
                            'hsl(89, 43%, 51%)',
                            'hsla(89, 43%, 51%, 0.6)',
                          ]"
                        ></sketch-picker>
                      </div>
                    </td>
                  </tr>
                </TransitionExpand>

                <TransitionExpand>
                  <tr
                    v-if="showStylingOptions === 2"
                    key="BackButtonBackground"
                  >
                    <td>
                      <div class="level">
                        Back button background color:
                        <button
                          class="button level-right"
                          v-on:click="toggleBackButtonBackground"
                          v-bind:style="{
                            background: BackButtonBackground.colors.hex,
                          }"
                        ></button>
                      </div>
                      <div class="level-item">
                        <sketch-picker
                          position="absolute"
                          v-show="BackButtonBackground.isOpen"
                          v-model="BackButtonBackground.colors"
                          :value="BackButtonBackground.colors"
                          :preset-colors="[
                            '#f00',
                            '#00ff00',
                            '#00ff0055',
                            'rgb(201, 76, 76)',
                            'rgba(0,0,255,1)',
                            'hsl(89, 43%, 51%)',
                            'hsla(89, 43%, 51%, 0.6)',
                          ]"
                        ></sketch-picker>
                      </div>
                    </td>
                  </tr>
                </TransitionExpand>

                <TransitionExpand>
                  <tr v-if="showStylingOptions === 2" key="BackButton">
                    <td>
                      <div class="level">
                        Back button text color:
                        <button
                          class="button level-right"
                          v-on:click="toggleBackButton"
                          v-bind:style="{ background: BackButton.colors.hex }"
                        ></button>
                      </div>
                      <div class="level-item">
                        <sketch-picker
                          position="absolute"
                          v-show="BackButton.isOpen"
                          v-model="BackButton.colors"
                          :value="BackButton.colors"
                          :preset-colors="[
                            '#f00',
                            '#00ff00',
                            '#00ff0055',
                            'rgb(201, 76, 76)',
                            'rgba(0,0,255,1)',
                            'hsl(89, 43%, 51%)',
                            'hsla(89, 43%, 51%, 0.6)',
                          ]"
                        ></sketch-picker>
                      </div>
                    </td>
                  </tr>
                </TransitionExpand>

                <TransitionExpand>
                  <tr
                    v-if="showStylingOptions === 2"
                    key="ExitButtonBackground"
                  >
                    <td>
                      <div class="level">
                        Exit button background color:
                        <button
                          class="button level-right"
                          v-on:click="toggleExitButtonBackground"
                          v-bind:style="{
                            background: ExitButtonBackground.colors.hex,
                          }"
                        ></button>
                      </div>
                      <div class="level-item">
                        <sketch-picker
                          position="absolute"
                          v-show="ExitButtonBackground.isOpen"
                          v-model="ExitButtonBackground.colors"
                          :value="ExitButtonBackground.colors"
                          :preset-colors="[
                            '#f00',
                            '#00ff00',
                            '#00ff0055',
                            'rgb(201, 76, 76)',
                            'rgba(0,0,255,1)',
                            'hsl(89, 43%, 51%)',
                            'hsla(89, 43%, 51%, 0.6)',
                          ]"
                        ></sketch-picker>
                      </div>
                    </td>
                  </tr>
                </TransitionExpand>

                <TransitionExpand>
                  <tr v-if="showStylingOptions === 2" key="ExitButton">
                    <td>
                      <div class="level">
                        Exit button text color:
                        <button
                          class="button level-right"
                          v-on:click="toggleExitButton"
                          v-bind:style="{ background: ExitButton.colors.hex }"
                        ></button>
                      </div>
                      <div class="level-item">
                        <sketch-picker
                          position="absolute"
                          v-show="ExitButton.isOpen"
                          v-model="ExitButton.colors"
                          :value="ExitButton.colors"
                          :preset-colors="[
                            '#f00',
                            '#00ff00',
                            '#00ff0055',
                            'rgb(201, 76, 76)',
                            'rgba(0,0,255,1)',
                            'hsl(89, 43%, 51%)',
                            'hsla(89, 43%, 51%, 0.6)',
                          ]"
                        ></sketch-picker>
                      </div>
                    </td>
                  </tr>
                </TransitionExpand>

                <TransitionExpand>
                  <tr
                    v-if="showStylingOptions === 2"
                    key="NavButtonsBorderWidth"
                  >
                    <td>
                      <div class="level">
                        Width of button borders
                        <button
                          class="button level-right"
                          v-on:click="toggleNavButtonsBorderWidth"
                        >
                          {{ NavButtonsBorderWidth.values }}
                        </button>
                      </div>
                      <div>
                        <vue-slider
                          class="has-margin-bottom has-margin-right has-margin-left"
                          v-show="NavButtonsBorderWidth.isOpen"
                          v-model="NavButtonsBorderWidth.values"
                          :marks="NavButtonsBorderWidth.marks"
                          :value="NavButtonsBorderWidth.values"
                          direction="ltr"
                          :min="0"
                          :max="3"
                          :minRange="1"
                          :interval="1"
                        ></vue-slider>
                      </div>
                    </td>
                  </tr>
                </TransitionExpand>

                <tr class="has-background-light">
                  <td>
                    <div class="level">
                      <a @click="showStylingOptions = 3"> Option cards </a>
                      <a v-if="showStylingOptions === 3">
                        <font-awesome-icon
                          @click="showStylingOptions = 3"
                          class="level-right"
                          :icon="['fas', 'chevron-down']"
                        />
                      </a>
                      <a v-else-if="showStylingOptions !== 3">
                        <font-awesome-icon
                          @click="showStylingOptions = 3"
                          class="level-right"
                          :icon="['fas', 'chevron-right']"
                        />
                      </a>
                    </div>
                  </td>
                </tr>

                <TransitionExpand>
                  <tr
                    v-if="showStylingOptions === 3"
                    key="OptionCardBackground"
                  >
                    <td>
                      <div class="level">
                        Background color:
                        <button
                          class="button level-right"
                          v-on:click="toggleOptionCardBackground"
                          v-bind:style="{
                            background: OptionCardBackground.colors.hex,
                          }"
                        ></button>
                      </div>
                      <div class="level-item">
                        <sketch-picker
                          position="absolute"
                          v-show="OptionCardBackground.isOpen"
                          v-model="OptionCardBackground.colors"
                          :value="OptionCardBackground.colors"
                          :preset-colors="[
                            '#f00',
                            '#00ff00',
                            '#00ff0055',
                            'rgb(201, 76, 76)',
                            'rgba(0,0,255,1)',
                            'hsl(89, 43%, 51%)',
                            'hsla(89, 43%, 51%, 0.6)',
                          ]"
                        ></sketch-picker>
                      </div>
                    </td>
                  </tr>
                </TransitionExpand>

                <TransitionExpand>
                  <tr
                    v-if="showStylingOptions === 3"
                    key="OptionCardSeatingBackground"
                  >
                    <td>
                      <div class="level">
                        Seat-arrangement background color:
                        <button
                          class="button level-right"
                          v-on:click="toggleOptionCardSeatingBackground"
                          v-bind:style="{
                            background: OptionCardSeatingBackground.colors.hex,
                          }"
                        ></button>
                      </div>
                      <div class="level-item">
                        <sketch-picker
                          position="absolute"
                          v-show="OptionCardSeatingBackground.isOpen"
                          v-model="OptionCardSeatingBackground.colors"
                          :value="OptionCardSeatingBackground.colors"
                          :preset-colors="[
                            '#f00',
                            '#00ff00',
                            '#00ff0055',
                            'rgb(201, 76, 76)',
                            'rgba(0,0,255,1)',
                            'hsl(89, 43%, 51%)',
                            'hsla(89, 43%, 51%, 0.6)',
                          ]"
                        ></sketch-picker>
                      </div>
                    </td>
                  </tr>
                </TransitionExpand>

                <TransitionExpand>
                  <tr
                    v-if="showStylingOptions === 3"
                    key="optionCardTitleTextColor"
                  >
                    <td>
                      <div class="level">
                        Title text color:
                        <button
                          class="button level-right"
                          v-on:click="toggleOptionCardTitle"
                          v-bind:style="{
                            background: OptionCardTitle.colors.hex,
                          }"
                        ></button>
                      </div>
                      <div class="level-item">
                        <sketch-picker
                          position="absolute"
                          v-show="OptionCardTitle.isOpen"
                          v-model="OptionCardTitle.colors"
                          :value="OptionCardTitle.colors"
                          :preset-colors="[
                            '#f00',
                            '#00ff00',
                            '#00ff0055',
                            'rgb(201, 76, 76)',
                            'rgba(0,0,255,1)',
                            'hsl(89, 43%, 51%)',
                            'hsla(89, 43%, 51%, 0.6)',
                          ]"
                        ></sketch-picker>
                      </div>
                    </td>
                  </tr>
                </TransitionExpand>

                <TransitionExpand>
                  <tr v-if="showStylingOptions === 3" key="OptionCardPricing">
                    <td>
                      <div class="level">
                        Pricing text color:
                        <button
                          class="button level-right"
                          v-on:click="toggleOptionCardPricing"
                          v-bind:style="{
                            background: OptionCardPricing.colors.hex,
                          }"
                        ></button>
                      </div>
                      <div class="level-item">
                        <sketch-picker
                          position="absolute"
                          v-show="OptionCardPricing.isOpen"
                          v-model="OptionCardPricing.colors"
                          :value="OptionCardPricing.colors"
                          :preset-colors="[
                            '#f00',
                            '#00ff00',
                            '#00ff0055',
                            'rgb(201, 76, 76)',
                            'rgba(0,0,255,1)',
                            'hsl(89, 43%, 51%)',
                            'hsla(89, 43%, 51%, 0.6)',
                          ]"
                        ></sketch-picker>
                      </div>
                    </td>
                  </tr>
                </TransitionExpand>

                <TransitionExpand>
                  <tr
                    v-if="showStylingOptions === 3"
                    key="OptionCardSeatingArrangement"
                  >
                    <td>
                      <div class="level">
                        Seat-arrangement text color:
                        <button
                          class="button level-right"
                          v-on:click="toggleOptionCardSeatingArrangement"
                          v-bind:style="{
                            background: OptionCardSeatingArrangement.colors.hex,
                          }"
                        ></button>
                      </div>
                      <div class="level-item">
                        <sketch-picker
                          position="absolute"
                          v-show="OptionCardSeatingArrangement.isOpen"
                          v-model="OptionCardSeatingArrangement.colors"
                          :value="OptionCardSeatingArrangement.colors"
                          :preset-colors="[
                            '#f00',
                            '#00ff00',
                            '#00ff0055',
                            'rgb(201, 76, 76)',
                            'rgba(0,0,255,1)',
                            'hsl(89, 43%, 51%)',
                            'hsla(89, 43%, 51%, 0.6)',
                          ]"
                        ></sketch-picker>
                      </div>
                    </td>
                  </tr>
                </TransitionExpand>

                <TransitionExpand>
                  <tr
                    v-if="showStylingOptions === 3"
                    key="OptionCardArrangementIconBackground"
                  >
                    <td>
                      <div class="level">
                        Seat-arrangement icon background color:
                        <button
                          class="button level-right"
                          v-on:click="toggleOptionCardArrangementIconBackground"
                          v-bind:style="{
                            background:
                              OptionCardArrangementIconBackground.colors.hex,
                          }"
                        ></button>
                      </div>
                      <div class="level-item">
                        <sketch-picker
                          position="absolute"
                          v-show="OptionCardArrangementIconBackground.isOpen"
                          v-model="OptionCardArrangementIconBackground.colors"
                          :value="OptionCardArrangementIconBackground.colors"
                          :preset-colors="[
                            '#f00',
                            '#00ff00',
                            '#00ff0055',
                            'rgb(201, 76, 76)',
                            'rgba(0,0,255,1)',
                            'hsl(89, 43%, 51%)',
                            'hsla(89, 43%, 51%, 0.6)',
                          ]"
                        ></sketch-picker>
                      </div>
                    </td>
                  </tr>
                </TransitionExpand>

                <TransitionExpand>
                  <tr
                    v-if="showStylingOptions === 3"
                    key="OptionCardFootageLabelBackground"
                  >
                    <td>
                      <div class="level">
                        Label square footage background color:
                        <button
                          class="button level-right"
                          v-on:click="toggleOptionCardFootageLabelBackground"
                          v-bind:style="{
                            background:
                              OptionCardFootageLabelBackground.colors.hex,
                          }"
                        ></button>
                      </div>
                      <div class="level-item">
                        <sketch-picker
                          position="absolute"
                          v-show="OptionCardFootageLabelBackground.isOpen"
                          v-model="OptionCardFootageLabelBackground.colors"
                          :value="OptionCardFootageLabelBackground.colors"
                          :preset-colors="[
                            '#f00',
                            '#00ff00',
                            '#00ff0055',
                            'rgb(201, 76, 76)',
                            'rgba(0,0,255,1)',
                            'hsl(89, 43%, 51%)',
                            'hsla(89, 43%, 51%, 0.6)',
                          ]"
                        ></sketch-picker>
                      </div>
                    </td>
                  </tr>
                </TransitionExpand>

                <TransitionExpand>
                  <tr
                    v-if="showStylingOptions === 3"
                    key="OptionCardFootageLabel"
                  >
                    <td>
                      <div class="level">
                        Label square footage text color:
                        <button
                          class="button level-right"
                          v-on:click="toggleOptionCardFootageLabel"
                          v-bind:style="{
                            background: OptionCardFootageLabel.colors.hex,
                          }"
                        ></button>
                      </div>
                      <div class="level-item">
                        <sketch-picker
                          position="absolute"
                          v-show="OptionCardFootageLabel.isOpen"
                          v-model="OptionCardFootageLabel.colors"
                          :value="OptionCardFootageLabel.colors"
                          :preset-colors="[
                            '#f00',
                            '#00ff00',
                            '#00ff0055',
                            'rgb(201, 76, 76)',
                            'rgba(0,0,255,1)',
                            'hsl(89, 43%, 51%)',
                            'hsla(89, 43%, 51%, 0.6)',
                          ]"
                        ></sketch-picker>
                      </div>
                    </td>
                  </tr>
                </TransitionExpand>

                <tr class="has-background-light">
                  <td>
                    <div class="level">
                      <a @click="showStylingOptions = 4"> Contact card </a>
                      <a v-if="showStylingOptions === 4">
                        <font-awesome-icon
                          @click="showStylingOptions = 4"
                          class="level-right"
                          :icon="['fas', 'chevron-down']"
                        />
                      </a>
                      <a v-else-if="showStylingOptions !== 4">
                        <font-awesome-icon
                          @click="showStylingOptions = 4"
                          class="level-right"
                          :icon="['fas', 'chevron-right']"
                        />
                      </a>
                    </div>
                  </td>
                </tr>

                <TransitionExpand>
                  <tr
                    v-if="showStylingOptions === 4"
                    key="ContactCardBackground"
                  >
                    <td>
                      <div class="level">
                        Background color:
                        <button
                          class="button level-right"
                          v-on:click="toggleContactCardBackground"
                          v-bind:style="{
                            background: ContactCardBackground.colors.hex,
                          }"
                        ></button>
                      </div>
                      <div class="level-item">
                        <sketch-picker
                          position="absolute"
                          v-show="ContactCardBackground.isOpen"
                          v-model="ContactCardBackground.colors"
                          :value="ContactCardBackground.colors"
                          :preset-colors="[
                            '#f00',
                            '#00ff00',
                            '#00ff0055',
                            'rgb(201, 76, 76)',
                            'rgba(0,0,255,1)',
                            'hsl(89, 43%, 51%)',
                            'hsla(89, 43%, 51%, 0.6)',
                          ]"
                        ></sketch-picker>
                      </div>
                    </td>
                  </tr>
                </TransitionExpand>

                <TransitionExpand>
                  <tr v-if="showStylingOptions === 4" key="ContactCardMessage">
                    <td>
                      <div class="level">
                        Text color:
                        <button
                          class="button level-right"
                          v-on:click="toggleContactCardMessage"
                          v-bind:style="{
                            background: ContactCardMessage.colors.hex,
                          }"
                        ></button>
                      </div>
                      <div class="level-item">
                        <sketch-picker
                          position="absolute"
                          v-show="ContactCardMessage.isOpen"
                          v-model="ContactCardMessage.colors"
                          :value="ContactCardMessage.colors"
                          :preset-colors="[
                            '#f00',
                            '#00ff00',
                            '#00ff0055',
                            'rgb(201, 76, 76)',
                            'rgba(0,0,255,1)',
                            'hsl(89, 43%, 51%)',
                            'hsla(89, 43%, 51%, 0.6)',
                          ]"
                        ></sketch-picker>
                      </div>
                    </td>
                  </tr>
                </TransitionExpand>

                <TransitionExpand>
                  <tr v-if="showStylingOptions === 4" key="ContactCardPhone">
                    <td>
                      <div class="level">
                        Phone text color:
                        <button
                          class="button level-right"
                          v-on:click="toggleContactCardPhone"
                          v-bind:style="{
                            background: ContactCardPhone.colors.hex,
                          }"
                        ></button>
                      </div>
                      <div class="level-item">
                        <sketch-picker
                          position="absolute"
                          v-show="ContactCardPhone.isOpen"
                          v-model="ContactCardPhone.colors"
                          :value="ContactCardPhone.colors"
                          :preset-colors="[
                            '#f00',
                            '#00ff00',
                            '#00ff0055',
                            'rgb(201, 76, 76)',
                            'rgba(0,0,255,1)',
                            'hsl(89, 43%, 51%)',
                            'hsla(89, 43%, 51%, 0.6)',
                          ]"
                        ></sketch-picker>
                      </div>
                    </td>
                  </tr>
                </TransitionExpand>

                <tr class="has-background-light">
                  <td>
                    <div class="level">
                      <a @click="showStylingOptions = 5"> Upsell card </a>
                      <a v-if="showStylingOptions === 5">
                        <font-awesome-icon
                          @click="showStylingOptions = 5"
                          class="level-right"
                          :icon="['fas', 'chevron-down']"
                        />
                      </a>
                      <a v-else-if="showStylingOptions !== 5">
                        <font-awesome-icon
                          @click="showStylingOptions = 5"
                          class="level-right"
                          :icon="['fas', 'chevron-right']"
                        />
                      </a>
                    </div>
                  </td>
                </tr>

                <TransitionExpand>
                  <tr
                    v-if="showStylingOptions === 5"
                    key="UpsellCardBackground"
                  >
                    <td>
                      <div class="level">
                        Background color:
                        <button
                          class="button level-right"
                          v-on:click="toggleUpsellCardBackground"
                          v-bind:style="{
                            background: UpsellCardBackground.colors.hex,
                          }"
                        ></button>
                      </div>
                      <div class="level-item">
                        <sketch-picker
                          position="absolute"
                          v-show="UpsellCardBackground.isOpen"
                          v-model="UpsellCardBackground.colors"
                          :value="UpsellCardBackground.colors"
                          :preset-colors="[
                            '#f00',
                            '#00ff00',
                            '#00ff0055',
                            'rgb(201, 76, 76)',
                            'rgba(0,0,255,1)',
                            'hsl(89, 43%, 51%)',
                            'hsla(89, 43%, 51%, 0.6)',
                          ]"
                        ></sketch-picker>
                      </div>
                    </td>
                  </tr>
                </TransitionExpand>

                <TransitionExpand>
                  <tr v-if="showStylingOptions === 5" key="UpsellCardTitle">
                    <td>
                      <div class="level">
                        Title text color:
                        <button
                          class="button level-right"
                          v-on:click="toggleUpsellCardTitle"
                          v-bind:style="{
                            background: UpsellCardTitle.colors.hex,
                          }"
                        ></button>
                      </div>
                      <div class="level-item">
                        <sketch-picker
                          position="absolute"
                          v-show="UpsellCardTitle.isOpen"
                          v-model="UpsellCardTitle.colors"
                          :value="UpsellCardTitle.colors"
                          :preset-colors="[
                            '#f00',
                            '#00ff00',
                            '#00ff0055',
                            'rgb(201, 76, 76)',
                            'rgba(0,0,255,1)',
                            'hsl(89, 43%, 51%)',
                            'hsla(89, 43%, 51%, 0.6)',
                          ]"
                        ></sketch-picker>
                      </div>
                    </td>
                  </tr>
                </TransitionExpand>

                <TransitionExpand>
                  <tr v-if="showStylingOptions === 5" key="UpsellCardSubTitle">
                    <td>
                      <div class="level">
                        Subtitle text color:
                        <button
                          class="button level-right"
                          v-on:click="toggleUpsellCardSubTitle"
                          v-bind:style="{
                            background: UpsellCardSubTitle.colors.hex,
                          }"
                        ></button>
                      </div>
                      <div class="level-item">
                        <sketch-picker
                          position="absolute"
                          v-show="UpsellCardSubTitle.isOpen"
                          v-model="UpsellCardSubTitle.colors"
                          :value="UpsellCardSubTitle.colors"
                          :preset-colors="[
                            '#f00',
                            '#00ff00',
                            '#00ff0055',
                            'rgb(201, 76, 76)',
                            'rgba(0,0,255,1)',
                            'hsl(89, 43%, 51%)',
                            'hsla(89, 43%, 51%, 0.6)',
                          ]"
                        ></sketch-picker>
                      </div>
                    </td>
                  </tr>
                </TransitionExpand>

                <TransitionExpand>
                  <tr v-if="showStylingOptions === 5" key="UpsellCardBody">
                    <td>
                      <div class="level">
                        Text color:
                        <button
                          class="button level-right"
                          v-on:click="toggleUpsellCardBody"
                          v-bind:style="{
                            background: UpsellCardBody.colors.hex,
                          }"
                        ></button>
                      </div>
                      <div class="level-item">
                        <sketch-picker
                          position="absolute"
                          v-show="UpsellCardBody.isOpen"
                          v-model="UpsellCardBody.colors"
                          :value="UpsellCardBody.colors"
                          :preset-colors="[
                            '#f00',
                            '#00ff00',
                            '#00ff0055',
                            'rgb(201, 76, 76)',
                            'rgba(0,0,255,1)',
                            'hsl(89, 43%, 51%)',
                            'hsla(89, 43%, 51%, 0.6)',
                          ]"
                        ></sketch-picker>
                      </div>
                    </td>
                  </tr>
                </TransitionExpand>

                <!-- <TransitionExpand>
                  <tr v-if="showStylingOptions === 5" key="UpsellCardIcon">
                    <td>
                      <div class="level">
                      Upsell card checkmark icon color: 
                      <button class="button level-right" v-on:click="toggleUpsellCardIcon" v-bind:style="{ background: UpsellCardIcon.colors.hex }"></button>
                      </div>
                      <div class="level-item" ><sketch-picker 
                      position="absolute"
                      v-show="UpsellCardIcon.isOpen"
                      v-model="UpsellCardIcon.colors"
                      :value="UpsellCardIcon.colors"
                      :preset-colors="[
                      '#f00', '#00ff00', '#00ff0055', 'rgb(201, 76, 76)', 'rgba(0,0,255,1)', 'hsl(89, 43%, 51%)', 'hsla(89, 43%, 51%, 0.6)'
                      ]"
                      ></sketch-picker>
                      </div>
                    </td>
                  </tr>
                  </TransitionExpand> -->

                <tr class="has-background-light">
                  <td>
                    <div class="level">
                      <a @click="showStylingOptions = 6">
                        Button & input settings
                      </a>
                      <a v-if="showStylingOptions === 6">
                        <font-awesome-icon
                          @click="showStylingOptions = 6"
                          class="level-right"
                          :icon="['fas', 'chevron-down']"
                        />
                      </a>
                      <a v-else-if="showStylingOptions !== 6">
                        <font-awesome-icon
                          @click="showStylingOptions = 6"
                          class="level-right"
                          :icon="['fas', 'chevron-right']"
                        />
                      </a>
                    </div>
                  </td>
                </tr>

                <TransitionExpand>
                  <tr v-if="showStylingOptions === 6" key="InputText">
                    <td>
                      <div class="level">
                        Input text color:
                        <button
                          class="button level-right"
                          v-on:click="toggleInputText"
                          v-bind:style="{ background: InputText.colors.hex }"
                        ></button>
                      </div>
                      <div class="level-item">
                        <sketch-picker
                          position="absolute"
                          v-show="InputText.isOpen"
                          v-model="InputText.colors"
                          :value="InputText.colors"
                          :preset-colors="[
                            '#f00',
                            '#00ff00',
                            '#00ff0055',
                            'rgb(201, 76, 76)',
                            'rgba(0,0,255,1)',
                            'hsl(89, 43%, 51%)',
                            'hsla(89, 43%, 51%, 0.6)',
                          ]"
                        ></sketch-picker>
                      </div>
                    </td>
                  </tr>
                </TransitionExpand>

                <TransitionExpand>
                  <tr v-if="showStylingOptions === 6" key="InputBackground">
                    <td>
                      <div class="level">
                        Input background color:
                        <button
                          class="button level-right"
                          v-on:click="toggleInputBackground"
                          v-bind:style="{
                            background: InputBackground.colors.hex,
                          }"
                        ></button>
                      </div>
                      <div class="level-item">
                        <sketch-picker
                          position="absolute"
                          v-show="InputBackground.isOpen"
                          v-model="InputBackground.colors"
                          :value="InputBackground.colors"
                          :preset-colors="[
                            '#f00',
                            '#00ff00',
                            '#00ff0055',
                            'rgb(201, 76, 76)',
                            'rgba(0,0,255,1)',
                            'hsl(89, 43%, 51%)',
                            'hsla(89, 43%, 51%, 0.6)',
                          ]"
                        ></sketch-picker>
                      </div>
                    </td>
                  </tr>
                </TransitionExpand>

                <TransitionExpand>
                  <tr v-if="showStylingOptions === 6" key="ButtonActive">
                    <td>
                      <div class="level">
                        Buttons text color (active buttons):
                        <button
                          class="button level-right"
                          v-on:click="toggleButtonActive"
                          v-bind:style="{ background: ButtonActive.colors.hex }"
                        ></button>
                      </div>
                      <div class="level-item">
                        <sketch-picker
                          position="absolute"
                          v-show="ButtonActive.isOpen"
                          v-model="ButtonActive.colors"
                          :value="ButtonActive.colors"
                          :preset-colors="[
                            '#f00',
                            '#00ff00',
                            '#00ff0055',
                            'rgb(201, 76, 76)',
                            'rgba(0,0,255,1)',
                            'hsl(89, 43%, 51%)',
                            'hsla(89, 43%, 51%, 0.6)',
                          ]"
                        ></sketch-picker>
                      </div>
                    </td>
                  </tr>
                </TransitionExpand>

                <TransitionExpand>
                  <tr
                    v-if="showStylingOptions === 6"
                    key="ButtonActiveBackground"
                  >
                    <td>
                      <div class="level">
                        Buttons background color (active buttons):
                        <button
                          class="button level-right"
                          v-on:click="toggleButtonActiveBackground"
                          v-bind:style="{
                            background: ButtonActiveBackground.colors.hex,
                          }"
                        ></button>
                      </div>
                      <div class="level-item">
                        <sketch-picker
                          position="absolute"
                          v-show="ButtonActiveBackground.isOpen"
                          v-model="ButtonActiveBackground.colors"
                          :value="ButtonActiveBackground.colors"
                          :preset-colors="[
                            '#f00',
                            '#00ff00',
                            '#00ff0055',
                            'rgb(201, 76, 76)',
                            'rgba(0,0,255,1)',
                            'hsl(89, 43%, 51%)',
                            'hsla(89, 43%, 51%, 0.6)',
                          ]"
                        ></sketch-picker>
                      </div>
                    </td>
                  </tr>
                </TransitionExpand>

                <TransitionExpand>
                  <tr v-if="showStylingOptions === 6" key="ButtonDisabled">
                    <td>
                      <div class="level">
                        Buttons text color (disabled buttons):
                        <button
                          class="button level-right"
                          v-on:click="toggleButtonDisabled"
                          v-bind:style="{
                            background: ButtonDisabled.colors.hex,
                          }"
                        ></button>
                      </div>
                      <div class="level-item">
                        <sketch-picker
                          position="absolute"
                          v-show="ButtonDisabled.isOpen"
                          v-model="ButtonDisabled.colors"
                          :value="ButtonDisabled.colors"
                          :preset-colors="[
                            '#f00',
                            '#00ff00',
                            '#00ff0055',
                            'rgb(201, 76, 76)',
                            'rgba(0,0,255,1)',
                            'hsl(89, 43%, 51%)',
                            'hsla(89, 43%, 51%, 0.6)',
                          ]"
                        ></sketch-picker>
                      </div>
                    </td>
                  </tr>
                </TransitionExpand>

                <TransitionExpand>
                  <tr
                    v-if="showStylingOptions === 6"
                    key="ButtonDisabledBackground"
                  >
                    <td>
                      <div class="level">
                        Buttons background color (disabled buttons):
                        <button
                          class="button level-right"
                          v-on:click="toggleButtonDisabledBackground"
                          v-bind:style="{
                            background: ButtonDisabledBackground.colors.hex,
                          }"
                        ></button>
                      </div>
                      <div class="level-item">
                        <sketch-picker
                          position="absolute"
                          v-show="ButtonDisabledBackground.isOpen"
                          v-model="ButtonDisabledBackground.colors"
                          :value="ButtonDisabledBackground.colors"
                          :preset-colors="[
                            '#f00',
                            '#00ff00',
                            '#00ff0055',
                            'rgb(201, 76, 76)',
                            'rgba(0,0,255,1)',
                            'hsl(89, 43%, 51%)',
                            'hsla(89, 43%, 51%, 0.6)',
                          ]"
                        ></sketch-picker>
                      </div>
                    </td>
                  </tr>
                </TransitionExpand>

                <TransitionExpand>
                  <tr
                    v-if="showStylingOptions === 6"
                    key="ButtonHoverBackground"
                  >
                    <td>
                      <div class="level">
                        Buttons background color (on hover):
                        <button
                          class="button level-right"
                          v-on:click="toggleButtonHoverBackground"
                          v-bind:style="{
                            background: ButtonHoverBackground.colors.hex,
                          }"
                        ></button>
                      </div>
                      <div class="level-item">
                        <sketch-picker
                          position="absolute"
                          v-show="ButtonHoverBackground.isOpen"
                          v-model="ButtonHoverBackground.colors"
                          :value="ButtonHoverBackground.colors"
                          :preset-colors="[
                            '#f00',
                            '#00ff00',
                            '#00ff0055',
                            'rgb(201, 76, 76)',
                            'rgba(0,0,255,1)',
                            'hsl(89, 43%, 51%)',
                            'hsla(89, 43%, 51%, 0.6)',
                          ]"
                        ></sketch-picker>
                      </div>
                    </td>
                  </tr>
                </TransitionExpand>

                <TransitionExpand>
                  <tr v-if="showStylingOptions === 6" key="ButtonBorderWidth">
                    <td>
                      <div class="level">
                        Buttons border width:
                        <button
                          class="button level-right"
                          v-on:click="toggleButtonBorderWidth"
                        >
                          {{ ButtonBorderWidth.values }}
                        </button>
                      </div>
                      <div>
                        <vue-slider
                          class="has-margin-bottom has-margin-right has-margin-left"
                          v-show="ButtonBorderWidth.isOpen"
                          v-model="ButtonBorderWidth.values"
                          :value="ButtonBorderWidth.values"
                          :marks="ButtonBorderWidth.marks"
                          direction="ltr"
                          :min="0"
                          :max="3"
                          :minRange="1"
                          :interval="1"
                        ></vue-slider>
                      </div>
                    </td>
                  </tr>
                </TransitionExpand>

                <TransitionExpand>
                  <tr v-if="showStylingOptions === 6" key="InputBorderWidth">
                    <td>
                      <div class="level">
                        Filters border width:
                        <button
                          class="button level-right"
                          v-on:click="toggleInputBorderWidth"
                        >
                          {{ InputBorderWidth.values }}
                        </button>
                      </div>
                      <div>
                        <vue-slider
                          class="has-margin-bottom has-margin-right has-margin-left"
                          v-show="InputBorderWidth.isOpen"
                          v-model="InputBorderWidth.values"
                          :value="InputBorderWidth.values"
                          :marks="InputBorderWidth.marks"
                          direction="ltr"
                          :min="0"
                          :max="3"
                          :minRange="1"
                          :interval="1"
                        ></vue-slider>
                      </div>
                    </td>
                  </tr>
                </TransitionExpand>

                <tr>
                  <td>
                    <div class="level-item has-margin-top">
                      <span>
                        <button
                          @click="resetStyling"
                          class="button is-info has-margin-right"
                        >
                          Reset styling
                        </button>
                        <button
                          @click="saveStyling"
                          class="button is-success has-margin-left"
                        >
                          Save styling
                        </button>
                      </span>
                    </div>
                  </td>
                </tr>
              </table>
            </div>

            <div class="column auto has-background-light has-margin-top">
              <div>
                <WidgetMockup
                  class="radius-widget-preview"
                  :HeaderBackground="this.HeaderBackground.colors.hex"
                  :GeneralBackground="this.GeneralBackground.colors.hex"
                  :OptionCardBackground="this.OptionCardBackground.colors.hex"
                  :OptionCardSeatingBackground="
                    this.OptionCardSeatingBackground.colors.hex
                  "
                  :ContactCardBackground="this.ContactCardBackground.colors.hex"
                  :UpsellCardBackground="this.UpsellCardBackground.colors.hex"
                  :GeneralBorderColor="this.GeneralBorderColor.colors.hex"
                  :OptionCardTitle="this.OptionCardTitle.colors.hex"
                  :OptionCardPricing="this.OptionCardPricing.colors.hex"
                  :NavCompleted="this.NavCompleted.colors.hex"
                  :NavInactive="this.NavInactive.colors.hex"
                  :OptionCardSeatingArrangement="
                    this.OptionCardSeatingArrangement.colors.hex
                  "
                  :GeneralTitleColor="this.GeneralTitleColor.colors.hex"
                  :ContactCardMessage="this.ContactCardMessage.colors.hex"
                  :ContactCardPhone="this.ContactCardPhone.colors.hex"
                  :ButtonActive="this.ButtonActive.colors.hex"
                  :ButtonActiveBackground="
                    this.ButtonActiveBackground.colors.hex
                  "
                  :ButtonDisabled="this.ButtonDisabled.colors.hex"
                  :ButtonDisabledBackground="
                    this.ButtonDisabledBackground.colors.hex
                  "
                  :ButtonHoverBackground="this.ButtonHoverBackground.colors.hex"
                  :OptionCardFootageLabelBackground="
                    this.OptionCardFootageLabelBackground.colors.hex
                  "
                  :BackButtonBackground="this.BackButtonBackground.colors.hex"
                  :ExitButtonBackground="this.ExitButtonBackground.colors.hex"
                  :NavArrows="this.NavArrows.colors.hex"
                  :GeneralRadius="this.GeneralRadius.values"
                  :NavButtonsBorderWidth="this.NavButtonsBorderWidth.values"
                  :UpsellCardBody="this.UpsellCardBody.colors.hex"
                  :UpsellCardSubTitle="this.UpsellCardSubTitle.colors.hex"
                  :UpsellCardTitle="this.UpsellCardTitle.colors.hex"
                  :OptionCardFootageLabel="
                    this.OptionCardFootageLabel.colors.hex
                  "
                  :InputText="this.InputText.colors.hex"
                  :InputBackground="this.InputBackground.colors.hex"
                  :ButtonBorderWidth="this.ButtonBorderWidth.values"
                  :BackButton="this.BackButton.colors.hex"
                  :ExitButton="this.ExitButton.colors.hex"
                  :NavCurrent="this.NavCurrent.colors.hex"
                  :OptionCardArrangementIconBackground="
                    this.OptionCardArrangementIconBackground.colors.hex
                  "
                  :InputBorderWidth="this.InputBorderWidth.values"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { Sketch } from 'vue-color'
import widgetProvider from '@/providers/widget'
import TransitionExpand from '@/components/UI/TransitionExpand'
import WidgetMockup from '../Widgets/WidgetMockup'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'

export default {
  components: {
    'sketch-picker': Sketch,
    WidgetMockup,
    VueSlider,
    TransitionExpand,
  },

  data() {
    return {
      showStyling: false,
      isSaving: false,
      isSavingSuccess: false,
      isSavingError: false,
      widgets: [],
      widgetId: Number(this.$route.params.widgetId),
      mWidget: null,
      isLoading: false,
      showStylingOptions: 1,
      originalWidget: null,
      originalStyling: null,
      GeneralRadius: {
        isOpen: true,
        values: 5,
        marks: {
          0: 'none',
          5: 'small',
          10: 'medium',
          15: 'heavy',
          20: 'extreme',
        },
      },
      NavButtonsBorderWidth: {
        isOpen: true,
        values: 1,
        marks: {
          0: 'none',
          1: 'small',
          2: 'medium',
          3: 'thick',
        },
      },
      ButtonBorderWidth: {
        isOpen: true,
        values: 1,
        marks: {
          0: 'none',
          1: 'small',
          2: 'medium',
          3: 'thick',
        },
      },
      InputBorderWidth: {
        isOpen: true,
        values: 1,
        marks: {
          0: 'none',
          1: 'small',
          2: 'medium',
          3: 'thick',
        },
      },
      GeneralBackground: {
        isOpen: false,
        colors: {
          hex: '#FFFFFF',
          source: 'hex',
        },
      },
      HeaderBackground: {
        isOpen: false,
        colors: {
          hex: '#00BAFF',
          source: 'hex',
        },
      },
      OptionCardTitle: {
        isOpen: false,
        colors: {
          hex: '#000000',
          source: 'hex',
        },
      },
      OptionCardSeatingBackground: {
        isOpen: false,
        colors: {
          hex: '#00BAFF',
          source: 'hex',
        },
      },
      ContactCardBackground: {
        isOpen: false,
        colors: {
          hex: '#95C11E',
          source: 'hex',
        },
      },
      UpsellCardBackground: {
        isOpen: false,
        colors: {
          hex: '#95C11E',
          source: 'hex',
        },
      },
      GeneralBorderColor: {
        isOpen: false,
        colors: {
          hex: '#000000',
          source: 'hex',
        },
      },
      OptionCardBackground: {
        isOpen: false,
        colors: {
          hex: '#95C11E',
          source: 'hex',
        },
      },
      OptionCardPricing: {
        isOpen: false,
        colors: {
          hex: '#000000',
          source: 'hex',
        },
      },
      NavCompleted: {
        isOpen: false,
        colors: {
          hex: '#000000',
          source: 'hex',
        },
      },
      NavInactive: {
        isOpen: false,
        colors: {
          hex: '#000000',
          source: 'hex',
        },
      },
      OptionCardSeatingArrangement: {
        isOpen: false,
        colors: {
          hex: '#FFFFFF',
          source: 'hex',
        },
      },
      GeneralTitleColor: {
        isOpen: false,
        colors: {
          hex: '#000000',
          source: 'hex',
        },
      },
      ContactCardMessage: {
        isOpen: false,
        colors: {
          hex: '#000000',
          source: 'hex',
        },
      },
      ContactCardPhone: {
        isOpen: false,
        colors: {
          hex: '#000000',
          source: 'hex',
        },
      },
      ButtonActive: {
        isOpen: false,
        colors: {
          hex: '#000000',
          source: 'hex',
        },
      },
      ButtonActiveBackground: {
        isOpen: false,
        colors: {
          hex: '#95C11E',
          source: 'hex',
        },
      },
      ButtonDisabled: {
        isOpen: false,
        colors: {
          hex: '#C1C1C1',
          source: 'hex',
        },
      },
      ButtonDisabledBackground: {
        isOpen: false,
        colors: {
          hex: '#95C11E',
          source: 'hex',
        },
      },
      ButtonHoverBackground: {
        isOpen: false,
        colors: {
          hex: '#95C11E',
          source: 'hex',
        },
      },
      OptionCardFootageLabelBackground: {
        isOpen: false,
        colors: {
          hex: '#00BAFF',
          source: 'hex',
        },
      },
      BackButtonBackground: {
        isOpen: false,
        colors: {
          hex: '#95C11E',
          source: 'hex',
        },
      },
      ExitButtonBackground: {
        isOpen: false,
        colors: {
          hex: '#95C11E',
          source: 'hex',
        },
      },
      NavArrows: {
        isOpen: false,
        colors: {
          hex: '#000000',
          source: 'hex',
        },
      },
      UpsellCardBody: {
        isOpen: false,
        colors: {
          hex: '#000000',
          source: 'hex',
        },
      },
      UpsellCardTitle: {
        isOpen: false,
        colors: {
          hex: '#000000',
          source: 'hex',
        },
      },
      UpsellCardSubTitle: {
        isOpen: false,
        colors: {
          hex: '#000000',
          source: 'hex',
        },
      },
      OptionCardFootageLabel: {
        isOpen: false,
        colors: {
          hex: '#000000',
          source: 'hex',
        },
      },
      //  UpsellCardIcon: {
      //   isOpen: false,
      //   colors: {
      //   "hex": "#000000",
      //   "source": "hex"
      //   }},
      InputText: {
        isOpen: false,
        colors: {
          hex: '#000000',
          source: 'hex',
        },
      },
      InputBackground: {
        isOpen: false,
        colors: {
          hex: '#FFFFFF',
          source: 'hex',
        },
      },
      BackButton: {
        isOpen: false,
        colors: {
          hex: '#000000',
          source: 'hex',
        },
      },
      ExitButton: {
        isOpen: false,
        colors: {
          hex: '#000000',
          source: 'hex',
        },
      },
      NavCurrent: {
        isOpen: false,
        colors: {
          hex: '#000000',
          source: 'hex',
        },
      },
      OptionCardArrangementIconBackground: {
        isOpen: false,
        colors: {
          hex: '#FFFFFF',
          source: 'hex',
        },
      },
    }
  },

  computed: {
    ...mapState('channelStore', ['channel']),
    ...mapState('widgetStore', ['selectedWidget']),

    cssVars() {
      return {
        '--GeneralRadius': this.GeneralRadius.values * 1 + 'px',
        '--GeneralBorderColor': this.GeneralBorderColor.colors.hex,
      }
    },
  },

  created() {
    this.mWidget = JSON.parse(JSON.stringify(this.selectedWidget))
    this.setupWidget()
  },

  methods: {
    setupWidget() {
      let self = this

      // setting up default styling object for widget
      if (self.mWidget.Styling === '') {
        self.mWidget.Styling = {
          General: {
            Text: {
              TitleColor: '#000000',
            },
            BackGround: {
              BackGroundColor: '#FFFFFF',
            },
            Border: {
              Radius: '5px',
              BorderColor: '#000000',
            },
          },
          Header: {
            text: {
              NavCompleted: '#000000',
              NavCurrent: '#000000',
              NavInactive: '#000000',
              NavArrows: '#000000',
              BackButton: '#000000',
              CloseButton: '#000000',
            },
            BackGround: {
              BackGroundColor: '#FFFFFF',
              BackButton: '#95C11E',
              ExitButton: '#95C11E',
            },
            Border: {
              NavButtonsBorderWidth: '1px',
            },
          },
          OptionCard: {
            Text: {
              TitleColor: '#000000',
              SeatArrangement: '#FFFFFF',
              Pricing: '#000000',
              Footage: '#000000',
            },
            BackGround: {
              BackGroundColor: '#95C11E',
              SeatArrangement: '#00BAFF',
              SeatArrangementIcon: '#FFFFFF',
              FootageLabel: '#00BAFF',
            },
            Border: {},
          },
          ContactCard: {
            Text: {
              Message: '#000000',
              Phone: '#000000',
            },
            BackGround: {
              BackGroundColor: '#95C11E',
            },
            Border: {},
          },
          UpsellCard: {
            Text: {
              TitleColor: '#000000',
              SubTitleColor: '#000000',
              BodyColor: '#000000',
            },
            BackGround: {
              BackGroundColor: '#95C11E',
            },
            Border: {},
          },
          Inputs: {
            Text: {
              Color: '#000000',
            },
            BackGround: {
              BackGroundColor: '#FFFFFF',
            },
            Border: {
              BorderWidth: '1px',
            },
          },
          Buttons: {
            Text: {
              Active: '#000000',
              Disabled: '#C1C1C1',
            },
            BackGround: {
              Active: '#95C11E',
              Disabled: '#95C11E',
              Hover: '#95C11E',
            },
            Border: {
              BorderWidth: '1px',
            },
          },
        }
      } else {
        self.mWidget.Styling = JSON.parse(self.mWidget.Styling)

        self.GeneralBackground.colors.hex =
          self.mWidget.Styling.General.BackGround.BackGroundColor
        self.HeaderBackground.colors.hex =
          self.mWidget.Styling.Header.BackGround.BackGroundColor
        self.OptionCardBackground.colors.hex =
          self.mWidget.Styling.OptionCard.BackGround.BackgroundColor //// BackgroundColor
        self.OptionCardSeatingBackground.colors.hex =
          self.mWidget.Styling.OptionCard.BackGround.SeatArrangement
        self.ContactCardBackground.colors.hex =
          self.mWidget.Styling.ContactCard.BackGround.BackGroundColor
        self.UpsellCardBackground.colors.hex =
          self.mWidget.Styling.UpsellCard.BackGround.BackGroundColor
        self.GeneralBorderColor.colors.hex =
          self.mWidget.Styling.General.Border.BorderColor
        self.OptionCardTitle.colors.hex =
          self.mWidget.Styling.OptionCard.Text.TitleColor
        self.OptionCardPricing.colors.hex =
          self.mWidget.Styling.OptionCard.Text.Pricing
        self.NavCompleted.colors.hex =
          self.mWidget.Styling.Header.text.NavCompleted /// text
        self.NavInactive.colors.hex =
          self.mWidget.Styling.Header.text.NavInactive /// text
        self.OptionCardSeatingArrangement.colors.hex =
          self.mWidget.Styling.OptionCard.Text.SeatArrangement
        self.GeneralTitleColor.colors.hex =
          self.mWidget.Styling.General.Text.TitleColor
        self.ContactCardMessage.colors.hex =
          self.mWidget.Styling.ContactCard.Text.Message
        self.ContactCardPhone.colors.hex =
          self.mWidget.Styling.ContactCard.Text.Phone
        self.ButtonActive.colors.hex = self.mWidget.Styling.Buttons.Text.Active
        self.ButtonActiveBackground.colors.hex =
          self.mWidget.Styling.Buttons.BackGround.Active
        self.ButtonDisabled.colors.hex =
          self.mWidget.Styling.Buttons.Text.Disabled
        self.ButtonDisabledBackground.colors.hex =
          self.mWidget.Styling.Buttons.BackGround.Disabled
        self.ButtonHoverBackground.colors.hex =
          self.mWidget.Styling.Buttons.BackGround.Hover
        self.OptionCardFootageLabelBackground.colors.hex =
          self.mWidget.Styling.OptionCard.BackGround.FootageLabel
        self.BackButtonBackground.colors.hex =
          self.mWidget.Styling.Header.BackGround.BackButton
        self.ExitButtonBackground.colors.hex =
          self.mWidget.Styling.Header.BackGround.ExitButton
        self.NavArrows.colors.hex = self.mWidget.Styling.Header.text.NavArrows /// text
        self.GeneralRadius.values = parseInt(
          self.mWidget.Styling.General.Border.Radius.slice(0, -2)
        )
        self.NavButtonsBorderWidth.values = parseInt(
          self.mWidget.Styling.Header.Border.NavButtonsBorderWidth.slice(0, -2)
        )
        self.UpsellCardBody.colors.hex =
          self.mWidget.Styling.UpsellCard.Text.BodyColor
        self.UpsellCardSubTitle.colors.hex =
          self.mWidget.Styling.UpsellCard.Text.SubTitleColor
        self.UpsellCardTitle.colors.hex =
          self.mWidget.Styling.UpsellCard.Text.TitleColor
        self.OptionCardFootageLabel.colors.hex =
          self.mWidget.Styling.OptionCard.Text.Footage
        self.InputText.colors.hex = self.mWidget.Styling.Inputs.Text.Color
        self.InputBackground.colors.hex =
          self.mWidget.Styling.Inputs.BackGround.BackGroundColor
        self.ButtonBorderWidth.values = parseInt(
          self.mWidget.Styling.Buttons.Border.BorderWidth.slice(0, -2)
        )
        self.BackButton.colors.hex = self.mWidget.Styling.Header.text.BackButton /// text
        self.ExitButton.colors.hex =
          self.mWidget.Styling.Header.text.CloseButton /// text
        self.NavCurrent.colors.hex = self.mWidget.Styling.Header.text.NavCurrent /// text
        self.OptionCardArrangementIconBackground.colors.hex =
          self.mWidget.Styling.OptionCard.BackGround.SeatArrangementIcon
        self.InputBorderWidth.values = parseInt(
          self.mWidget.Styling.Inputs.Border.BorderWidth.slice(0, -2)
        )
      }

      self.originalStyling = this.mWidget.Styling
    },

    embedCode() {
      let result = this.$stringHelper.encodeHtml(
        '<link href="https://widget.cyberdigma.nl/app.css" rel="stylesheet" /> #br#' +
          '<c_lab-bw id="c_lab-bw" widget-key="' +
          this.mWidget.Key +
          '" locale="nl" /> #br#' +
          '<script src="https://widget.cyberdigma.nl/app.js"></' +
          'script>'
      )

      return result.replace(/#br#/g, '</br>')
    },

    saveStyling() {
      let self = this

      ;(this.styling = {
        General: {
          Text: {
            TitleColor: this.GeneralTitleColor.colors.hex,
          },
          BackGround: {
            BackGroundColor: this.GeneralBackground.colors.hex,
          },
          Border: {
            Radius: this.GeneralRadius.values * 1 + 'px',
            BorderColor: this.GeneralBorderColor.colors.hex,
          },
        },
        Header: {
          text: {
            NavCompleted: this.NavCompleted.colors.hex,
            NavCurrent: this.NavCurrent.colors.hex,
            NavInactive: this.NavInactive.colors.hex,
            NavArrows: this.NavArrows.colors.hex,
            BackButton: this.BackButton.colors.hex,
            CloseButton: this.ExitButton.colors.hex,
          },
          BackGround: {
            BackGroundColor: this.HeaderBackground.colors.hex,
            BackButton: this.BackButtonBackground.colors.hex,
            ExitButton: this.ExitButtonBackground.colors.hex,
          },
          Border: {
            NavButtonsBorderWidth: this.NavButtonsBorderWidth.values * 1 + 'px',
          },
        },
        OptionCard: {
          Text: {
            TitleColor: this.OptionCardTitle.colors.hex,
            SeatArrangement: this.OptionCardSeatingArrangement.colors.hex,
            Pricing: this.OptionCardPricing.colors.hex,
            Footage: this.OptionCardFootageLabel.colors.hex,
          },
          BackGround: {
            BackgroundColor: this.OptionCardBackground.colors.hex,
            SeatArrangement: this.OptionCardSeatingBackground.colors.hex,
            SeatArrangementIcon:
              this.OptionCardArrangementIconBackground.colors.hex,
            FootageLabel: this.OptionCardFootageLabelBackground.colors.hex,
          },
          Border: {},
        },
        ContactCard: {
          Text: {
            Message: this.ContactCardMessage.colors.hex,
            Phone: this.ContactCardPhone.colors.hex,
          },
          BackGround: {
            BackGroundColor: this.ContactCardBackground.colors.hex,
          },
          Border: {},
        },
        UpsellCard: {
          Text: {
            TitleColor: this.UpsellCardTitle.colors.hex,
            SubTitleColor: this.UpsellCardSubTitle.colors.hex,
            BodyColor: this.UpsellCardBody.colors.hex,
          },
          BackGround: {
            BackGroundColor: this.UpsellCardBackground.colors.hex,
          },
          Border: {},
        },
        Inputs: {
          Text: {
            Color: this.InputText.colors.hex,
          },
          BackGround: {
            BackGroundColor: this.InputBackground.colors.hex,
          },
          Border: {
            BorderWidth: this.InputBorderWidth.values * 1 + 'px',
          },
        },
        Buttons: {
          Text: {
            Active: this.ButtonActive.colors.hex,
            Disabled: this.ButtonDisabled.colors.hex,
          },
          BackGround: {
            Active: this.ButtonActiveBackground.colors.hex,
            Disabled: this.ButtonDisabledBackground.colors.hex,
            Hover: this.ButtonHoverBackground.colors.hex,
          },
          Border: {
            BorderWidth: this.ButtonBorderWidth.values * 1 + 'px',
          },
        },
      }),
        (self.mWidget.Styling = JSON.stringify(this.styling))
      this.saveWidget(self.mWidget)
    },

    resetStyling() {
      let self = this

      self.GeneralBackground.colors.hex =
        self.originalStyling.General.BackGround.BackGroundColor
      self.HeaderBackground.colors.hex =
        self.originalStyling.Header.BackGround.BackGroundColor
      self.OptionCardBackground.colors.hex =
        self.originalStyling.OptionCard.BackGround.BackgroundColor //// BackgroundColor
      self.OptionCardSeatingBackground.colors.hex =
        self.originalStyling.OptionCard.BackGround.SeatArrangement
      self.ContactCardBackground.colors.hex =
        self.originalStyling.ContactCard.BackGround.BackGroundColor
      self.UpsellCardBackground.colors.hex =
        self.originalStyling.UpsellCard.BackGround.BackGroundColor
      self.GeneralBorderColor.colors.hex =
        self.originalStyling.General.Border.BorderColor
      self.OptionCardTitle.colors.hex =
        self.originalStyling.OptionCard.Text.TitleColor
      self.OptionCardPricing.colors.hex =
        self.originalStyling.OptionCard.Text.Pricing
      self.NavCompleted.colors.hex =
        self.originalStyling.Header.text.NavCompleted /// text
      self.NavInactive.colors.hex = self.originalStyling.Header.text.NavInactive /// text
      self.OptionCardSeatingArrangement.colors.hex =
        self.originalStyling.OptionCard.Text.SeatArrangement
      self.GeneralTitleColor.colors.hex =
        self.originalStyling.General.Text.TitleColor
      self.ContactCardMessage.colors.hex =
        self.originalStyling.ContactCard.Text.Message
      self.ContactCardPhone.colors.hex =
        self.originalStyling.ContactCard.Text.Phone
      self.ButtonActive.colors.hex = self.originalStyling.Buttons.Text.Active
      self.ButtonActiveBackground.colors.hex =
        self.originalStyling.Buttons.BackGround.Active
      self.ButtonDisabled.colors.hex =
        self.originalStyling.Buttons.Text.Disabled
      self.ButtonDisabledBackground.colors.hex =
        self.originalStyling.Buttons.BackGround.Disabled
      self.ButtonHoverBackground.colors.hex =
        self.originalStyling.Buttons.BackGround.Hover
      self.OptionCardFootageLabelBackground.colors.hex =
        self.originalStyling.OptionCard.BackGround.FootageLabel
      self.BackButtonBackground.colors.hex =
        self.originalStyling.Header.BackGround.BackButton
      self.ExitButtonBackground.colors.hex =
        self.originalStyling.Header.BackGround.ExitButton
      self.NavArrows.colors.hex = self.originalStyling.Header.text.NavArrows /// text
      self.GeneralRadius.values = parseInt(
        self.originalStyling.General.Border.Radius.slice(0, -2)
      )
      self.NavButtonsBorderWidth.values = parseInt(
        self.originalStyling.Header.Border.NavButtonsBorderWidth.slice(0, -2)
      )
      self.UpsellCardBody.colors.hex =
        self.originalStyling.UpsellCard.Text.BodyColor
      self.UpsellCardSubTitle.colors.hex =
        self.originalStyling.UpsellCard.Text.SubTitleColor
      self.UpsellCardTitle.colors.hex =
        self.originalStyling.UpsellCard.Text.TitleColor
      self.OptionCardFootageLabel.colors.hex =
        self.originalStyling.OptionCard.Text.Footage
      self.InputText.colors.hex = self.originalStyling.Inputs.Text.Color
      self.InputBackground.colors.hex =
        self.originalStyling.Inputs.BackGround.BackGroundColor
      self.ButtonBorderWidth.values = parseInt(
        self.originalStyling.Buttons.Border.BorderWidth.slice(0, -2)
      )
      self.BackButton.colors.hex = self.originalStyling.Header.text.BackButton /// text
      self.ExitButton.colors.hex = self.originalStyling.Header.text.CloseButton /// text
      self.NavCurrent.colors.hex = self.originalStyling.Header.text.NavCurrent /// text
      self.OptionCardArrangementIconBackground.colors.hex =
        self.originalStyling.OptionCard.BackGround.SeatArrangementIcon
      self.InputBorderWidth.values = parseInt(
        self.originalStyling.Inputs.Border.BorderWidth.slice(0, -2)
      )
    },

    saveWidget() {
      let self = this

      if (!self.isSaving) {
        self.isSaving = true
        self.mWidget.Styling = JSON.stringify(this.styling)

        widgetProvider.methods
          .updateLocationSearchWidgets(this.mWidget)
          .then((response) => {
            if (response.status === 200) {
              self.isSavingSuccess = true

              setTimeout(() => {}, 1500)
            }
          })
          .catch((error) => {
            self.isSavingError = true
          })
          .finally(() => {
            this.isSaving = false
          })
      }
    },

    toggleGeneralBackground() {
      this.GeneralBackground.isOpen = !this.GeneralBackground.isOpen
      this.GeneralBackground.colors.source = 'hex'
    },

    toggleHeaderBackground() {
      this.HeaderBackground.isOpen = !this.HeaderBackground.isOpen
      this.HeaderBackground.colors.source = 'hex'
    },

    toggleOptionCardBackground() {
      this.OptionCardBackground.isOpen = !this.OptionCardBackground.isOpen
      this.OptionCardBackground.colors.source = 'hex'
    },

    toggleOptionCardSeatingBackground() {
      this.OptionCardSeatingBackground.isOpen =
        !this.OptionCardSeatingBackground.isOpen
      this.OptionCardSeatingBackground.colors.source = 'hex'
    },

    toggleContactCardBackground() {
      this.ContactCardBackground.isOpen = !this.ContactCardBackground.isOpen
      this.ContactCardBackground.colors.source = 'hex'
    },

    toggleUpsellCardBackground() {
      this.UpsellCardBackground.isOpen = !this.UpsellCardBackground.isOpen
      this.UpsellCardBackground.colors.source = 'hex'
    },

    toggleOptionCardTitle() {
      this.OptionCardTitle.isOpen = !this.OptionCardTitle.isOpen
      this.OptionCardTitle.colors.source = 'hex'
    },

    toggleGeneralBorderColor() {
      this.GeneralBorderColor.isOpen = !this.GeneralBorderColor.isOpen
      this.GeneralBorderColor.colors.source = 'hex'
    },

    toggleOptionCardPricing() {
      this.OptionCardPricing.isOpen = !this.OptionCardPricing.isOpen
      this.OptionCardPricing.colors.source = 'hex'
    },

    toggleNavCompleted() {
      this.NavCompleted.isOpen = !this.NavCompleted.isOpen
      this.NavCompleted.colors.source = 'hex'
    },

    toggleNavCurrent() {
      this.NavCurrent.isOpen = !this.NavCurrent.isOpen
      this.NavCurrent.colors.source = 'hex'
    },

    toggleNavInactive() {
      this.NavInactive.isOpen = !this.NavInactive.isOpen
      this.NavInactive.colors.source = 'hex'
    },

    toggleOptionCardSeatingArrangement() {
      this.OptionCardSeatingArrangement.isOpen =
        !this.OptionCardSeatingArrangement.isOpen
      this.OptionCardSeatingArrangement.colors.source = 'hex'
    },

    toggleGeneralTitleColor() {
      this.GeneralTitleColor.isOpen = !this.GeneralTitleColor.isOpen
      this.GeneralTitleColor.colors.source = 'hex'
    },

    toggleContactCardMessage() {
      this.ContactCardMessage.isOpen = !this.ContactCardMessage.isOpen
      this.ContactCardMessage.colors.source = 'hex'
    },

    toggleContactCardPhone() {
      this.ContactCardPhone.isOpen = !this.ContactCardPhone.isOpen
      this.ContactCardPhone.colors.source = 'hex'
    },

    toggleButtonActive() {
      this.ButtonActive.isOpen = !this.ButtonActive.isOpen
      this.ButtonActive.colors.source = 'hex'
    },

    toggleButtonActiveBackground() {
      this.ButtonActiveBackground.isOpen = !this.ButtonActiveBackground.isOpen
      this.ButtonActiveBackground.colors.source = 'hex'
    },

    toggleButtonDisabledBackground() {
      this.ButtonDisabledBackground.isOpen =
        !this.ButtonDisabledBackground.isOpen
      this.ButtonDisabledBackground.colors.source = 'hex'
    },

    toggleButtonDisabled() {
      this.ButtonDisabled.isOpen = !this.ButtonDisabled.isOpen
      this.ButtonDisabled.colors.source = 'hex'
    },

    toggleButtonHoverBackground() {
      this.ButtonHoverBackground.isOpen = !this.ButtonHoverBackground.isOpen
      this.ButtonHoverBackground.colors.source = 'hex'
    },

    toggleOptionCardFootageLabelBackground() {
      this.OptionCardFootageLabelBackground.isOpen =
        !this.OptionCardFootageLabelBackground.isOpen
      this.OptionCardFootageLabelBackground.colors.source = 'hex'
    },
    toggleBackButtonBackground() {
      this.BackButtonBackground.isOpen = !this.BackButtonBackground.isOpen
      this.BackButtonBackground.colors.source = 'hex'
    },
    toggleExitButtonBackground() {
      this.ExitButtonBackground.isOpen = !this.ExitButtonBackground.isOpen
      this.ExitButtonBackground.colors.source = 'hex'
    },
    toggleNavArrows() {
      this.NavArrows.isOpen = !this.NavArrows.isOpen
      this.NavArrows.colors.source = 'hex'
    },
    toggleGeneralRadius() {
      this.GeneralRadius.isOpen = !this.GeneralRadius.isOpen
    },
    toggleNavButtonsBorderWidth() {
      this.NavButtonsBorderWidth.isOpen = !this.NavButtonsBorderWidth.isOpen
    },

    toggleUpsellCardBody() {
      this.UpsellCardBody.isOpen = !this.UpsellCardBody.isOpen
      this.UpsellCardBody.colors.source = 'hex'
    },
    toggleUpsellCardSubTitle() {
      this.UpsellCardSubTitle.isOpen = !this.UpsellCardSubTitle.isOpen
      this.UpsellCardSubTitle.colors.source = 'hex'
    },
    toggleUpsellCardTitle() {
      this.UpsellCardTitle.isOpen = !this.UpsellCardTitle.isOpen
      this.UpsellCardTitle.colors.source = 'hex'
    },
    toggleOptionCardFootageLabel() {
      this.OptionCardFootageLabel.isOpen = !this.OptionCardFootageLabel.isOpen
      this.OptionCardFootageLabel.colors.source = 'hex'
    },
    // toggleUpsellCardIcon() {
    //   this.UpsellCardIcon.isOpen = !this.UpsellCardIcon.isOpen
    //   this.UpsellCardIcon.colors.source = 'hex'
    // },
    //    :UpsellCardIcon="this.UpsellCardIcon.colors.hex"
    toggleInputText() {
      this.InputText.isOpen = !this.InputText.isOpen
      this.InputText.colors.source = 'hex'
    },
    toggleInputBackground() {
      this.InputBackground.isOpen = !this.InputBackground.isOpen
      this.InputBackground.colors.source = 'hex'
    },
    toggleButtonBorderWidth() {
      this.ButtonBorderWidth.isOpen = !this.ButtonBorderWidth.isOpen
    },
    toggleInputBorderWidth() {
      this.InputBorderWidth.isOpen = !this.InputBorderWidth.isOpen
    },
    toggleBackButton() {
      this.BackButton.isOpen = !this.BackButton.isOpen
      this.BackButton.colors.source = 'hex'
    },
    toggleExitButton() {
      this.ExitButton.isOpen = !this.ExitButton.isOpen
      this.ExitButton.colors.source = 'hex'
    },
    toggleOptionCardArrangementIconBackground() {
      this.OptionCardArrangementIconBackground.isOpen =
        !this.OptionCardArrangementIconBackground.isOpen
      this.OptionCardArrangementIconBackground.colors.source = 'hex'
    },
  },
}
</script>

<style scoped>
.picker {
  justify-content: right;
}

.radius-widget-preview {
  border-color: var(--GeneralBorderColor) !important;
  border-radius: var(--GeneralRadius) !important ;
}
</style>
