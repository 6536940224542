<template>
  <div>
    <ui-loader v-if="isLoading" />
    <div>
      <Message
        v-if="selectedWidget === null"
        class="is-warning"
        :message="'No widget found.'"
      />
      <template>
        <div class="columns is-multiline">
          <div class="column is-one-third">
            <div
              class="is-size-3 has-text-weight-bold"
              v-text="selectedWidget.Name"
            ></div>
          </div>

          <div class="column is-flex">
            <div class="filters is-inline-block has-margin-right">
              <div class="tabs-spaceSelection tabs is-toggle is-small">
                <ul>
                  <li
                    :class="{ 'is-active': this.selectedMeetingtypeId === 0 }"
                  >
                    <a @click="setSelectedMeetingtypeId(0)">
                      <span v-text="$t('General.All')"></span>
                    </a>
                  </li>
                  <li
                    id="tabs"
                    v-for="meetingtype in meetingtypes"
                    @click="setSelectedMeetingtypeId(meetingtype.MeetingtypeId)"
                    :class="{
                      'is-active':
                        meetingtype.MeetingtypeId === selectedMeetingtypeId,
                    }"
                    :key="meetingtype.MeetingtypeId"
                  >
                    <a>{{
                      $t(`General.MeetingType${meetingtype.MeetingtypeId}_Name`)
                    }}</a>
                  </li>
                </ul>
              </div>
            </div>

            <div class="filter-column is-inline-block">
              <ui-search-form
                dateMode="range"
                searchTermPlaceholder="Transaction activity"
                :showSearchInput="false"
                :isLoading="isSearching"
                :startDate="this.startDate"
                :endDate="this.endDate"
                :actionSearch="datesChanged"
              />
            </div>
          </div>
        </div>

        <ui-section-header class="has-margin-top-x2 level-item">
          <template v-slot:title>{{
            $t(
              'Components.Settings.Widgets.bookingsWidgetStats.Title_SearchesAndReservations'
            )
          }}</template>
        </ui-section-header>
        <!-- <ui-loader v-if="isSearching" /> -->

        <BarChart
          :chartdata="barChartDataset"
          :options="barChartOptions"
          class="barChart has-margin-bottom-x2"
        />

        <div class="columns is-multiline">
          <div class="column is-two-thirds">
            <ui-section-header>
              <template v-slot:title>
                {{
                  $t(
                    'Components.Settings.Widgets.bookingsWidgetStats.Title_TopPerformingDays'
                  )
                }}
              </template>
            </ui-section-header>

            <div class="columns is-multiline">
              <div class="column is-half">
                <p
                  class="title is-5"
                  v-text="
                    $t(
                      'Components.Settings.Widgets.bookingsWidgetStats.Label_Reservations'
                    )
                  "
                ></p>

                <div
                  v-if="
                    this.bestSearch.NrOfSearch === 0 &&
                    this.bestReservation.NrOfReservationCreated === 0
                  "
                >
                  <article class="message is-warning">
                    <div class="message-body">
                      {{
                        $t(
                          'Components.Settings.Widgets.bookingsWidgetStats.Message_NothingToShow'
                        )
                      }}
                    </div>
                  </article>
                </div>
                <div
                  v-else-if="
                    this.tempReservationStats === {} ||
                    this.tempReservationStats.length <= 3
                  "
                >
                  <article class="message is-warning">
                    <div class="message-body">
                      {{
                        $t(
                          'Components.Settings.Widgets.bookingsWidgetStats.Message_NotEnoughDays'
                        )
                      }}
                    </div>
                  </article>
                </div>
                <table class="table is-striped is-fullwidth">
                  <tbody>
                    <tr>
                      <td class="tableCell">
                        <span class="icon fa-stack">
                          <font-awesome-icon
                            class="fa-stack-2x"
                            :icon="['far', 'square']"
                          />
                          <strong class="fa-stack fa-1x rankNumber"
                            ><b class="is-size-3"> 1 </b></strong
                          >
                        </span>
                      </td>
                      <td>
                        <p class="is-size-5 has-text-left has-text-weight-bold">
                          {{ this.bestReservation.NrOfReservationCreated }}
                          {{
                            $t(
                              'Components.Settings.Widgets.bookingsWidgetStats.Label_Reservations'
                            )
                          }}
                        </p>
                        <p class="is-size-7 has-text-left is-italic">
                          {{
                            $t(
                              'Components.Settings.Widgets.bookingsWidgetStats.Label_On'
                            )
                          }}
                          <b>{{
                            this.bestReservation.Date
                              | parseIsoDateStringToDate
                              | dateFormat($i18n.locale)
                          }}</b>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td class="tableCell">
                        <span class="icon fa-stack">
                          <font-awesome-icon
                            class="fa-stack-2x"
                            :icon="['far', 'square']"
                          />
                          <strong class="fa-stack fa-1x rankNumber"
                            ><b class="is-size-3"> 2 </b></strong
                          >
                        </span>
                      </td>
                      <td>
                        <p class="is-size-5 has-text-left has-text-weight-bold">
                          {{
                            this.secondBestReservation.NrOfReservationCreated
                          }}
                          {{
                            $t(
                              'Components.Settings.Widgets.bookingsWidgetStats.Label_Reservations'
                            )
                          }}
                        </p>
                        <p class="is-size-7 has-text-left is-italic">
                          {{
                            $t(
                              'Components.Settings.Widgets.bookingsWidgetStats.Label_On'
                            )
                          }}
                          <strong>{{
                            this.secondBestReservation.Date
                              | parseIsoDateStringToDate
                              | dateFormat($i18n.locale)
                          }}</strong>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td class="tableCell">
                        <span class="icon fa-stack">
                          <font-awesome-icon
                            class="fa-stack-2x"
                            :icon="['far', 'square']"
                          />
                          <strong class="fa-stack fa-1x rankNumber"
                            ><b class="is-size-3"> 3 </b></strong
                          >
                        </span>
                      </td>
                      <td>
                        <p class="is-size-5 has-text-left has-text-weight-bold">
                          {{ this.thirdBestReservation.NrOfReservationCreated }}
                          {{
                            $t(
                              'Components.Settings.Widgets.bookingsWidgetStats.Label_Reservations'
                            )
                          }}
                        </p>
                        <p class="is-size-7 has-text-left is-italic">
                          {{
                            $t(
                              'Components.Settings.Widgets.bookingsWidgetStats.Label_On'
                            )
                          }}
                          <strong>{{
                            this.thirdBestReservation.Date
                              | parseIsoDateStringToDate
                              | dateFormat($i18n.locale)
                          }}</strong>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td class="tableCell">
                        <span class="icon fa-stack">
                          <font-awesome-icon
                            class="fa-stack-2x"
                            :icon="['far', 'square']"
                          />
                          <strong class="fa-stack fa-1x rankNumber"
                            ><strong class="is-size-3"> 4 </strong></strong
                          >
                        </span>
                      </td>
                      <td>
                        <p class="is-size-5 has-text-left has-text-weight-bold">
                          {{
                            this.fourthBestReservation.NrOfReservationCreated
                          }}
                          {{
                            $t(
                              'Components.Settings.Widgets.bookingsWidgetStats.Label_Reservations'
                            )
                          }}
                        </p>
                        <p class="is-size-7 has-text-left is-italic">
                          {{
                            $t(
                              'Components.Settings.Widgets.bookingsWidgetStats.Label_On'
                            )
                          }}
                          <strong>{{
                            this.fourthBestReservation.Date
                              | parseIsoDateStringToDate
                              | dateFormat($i18n.locale)
                          }}</strong>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td class="tableCell">
                        <span class="icon fa-stack">
                          <font-awesome-icon
                            class="fa-stack-2x"
                            :icon="['far', 'square']"
                          />
                          <strong class="fa-stack fa-1x rankNumber"
                            ><strong class="is-size-3"> 5 </strong></strong
                          >
                        </span>
                      </td>
                      <td>
                        <p class="is-size-5 has-text-left has-text-weight-bold">
                          {{ this.fifthBestReservation.NrOfReservationCreated }}
                          {{
                            $t(
                              'Components.Settings.Widgets.bookingsWidgetStats.Label_Reservations'
                            )
                          }}
                        </p>
                        <p class="is-size-7 has-text-left is-italic">
                          {{
                            $t(
                              'Components.Settings.Widgets.bookingsWidgetStats.Label_On'
                            )
                          }}
                          <b>{{
                            this.fifthBestReservation.Date
                              | parseIsoDateStringToDate
                              | dateFormat($i18n.locale)
                          }}</b>
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="column is-half">
                <p
                  class="title is-5"
                  v-text="
                    $t(
                      'Components.Settings.Widgets.bookingsWidgetStats.Label_Searches'
                    )
                  "
                ></p>

                <div
                  v-if="
                    this.bestSearch.NrOfSearch === 0 &&
                    this.bestReservation.NrOfReservationCreated === 0
                  "
                >
                  <article class="message is-warning">
                    <div class="message-body">
                      {{
                        $t(
                          'Components.Settings.Widgets.bookingsWidgetStats.Message_NothingToShow'
                        )
                      }}
                    </div>
                  </article>
                </div>

                <div
                  v-else-if="
                    this.tempSearchStats === {} ||
                    this.tempSearchStats.length <= 3
                  "
                >
                  <article class="message is-warning">
                    <div class="message-body">
                      {{
                        $t(
                          'Components.Settings.Widgets.bookingsWidgetStats.Message_NotEnoughDays'
                        )
                      }}
                    </div>
                  </article>
                </div>
                <table class="table is-striped is-fullwidth">
                  <tbody>
                    <tr>
                      <td class="tableCell">
                        <span class="icon fa-stack">
                          <font-awesome-icon
                            class="fa-stack-2x"
                            :icon="['far', 'square']"
                          />
                          <strong class="fa-stack fa-1x rankNumber"
                            ><b class="is-size-3"> 1 </b></strong
                          >
                        </span>
                      </td>
                      <td>
                        <p class="is-size-5 has-text-left has-text-weight-bold">
                          {{ this.bestSearch.NrOfSearch }}
                          {{
                            $t(
                              'Components.Settings.Widgets.bookingsWidgetStats.Label_Searches'
                            )
                          }}
                        </p>
                        <p class="is-size-7 has-text-left is-italic">
                          {{
                            $t(
                              'Components.Settings.Widgets.bookingsWidgetStats.Label_On'
                            )
                          }}
                          <b>{{
                            this.bestSearch.Date
                              | parseIsoDateStringToDate
                              | dateFormat($i18n.locale)
                          }}</b>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td class="tableCell">
                        <span class="icon fa-stack">
                          <font-awesome-icon
                            class="fa-stack-2x"
                            :icon="['far', 'square']"
                          />
                          <strong class="fa-stack fa-1x rankNumber"
                            ><b class="is-size-3"> 2 </b></strong
                          >
                        </span>
                      </td>
                      <td>
                        <p class="is-size-5 has-text-left has-text-weight-bold">
                          {{ this.secondBestSearch.NrOfSearch }}
                          {{
                            $t(
                              'Components.Settings.Widgets.bookingsWidgetStats.Label_Searches'
                            )
                          }}
                        </p>
                        <p class="is-size-7 has-text-left is-italic">
                          {{
                            $t(
                              'Components.Settings.Widgets.bookingsWidgetStats.Label_On'
                            )
                          }}
                          <b>{{
                            this.secondBestSearch.Date
                              | parseIsoDateStringToDate
                              | dateFormat($i18n.locale)
                          }}</b>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td class="tableCell">
                        <span class="icon fa-stack">
                          <font-awesome-icon
                            class="fa-stack-2x"
                            :icon="['far', 'square']"
                          />
                          <strong class="fa-stack fa-1x rankNumber"
                            ><b class="is-size-3"> 3 </b></strong
                          >
                        </span>
                      </td>
                      <td>
                        <p class="is-size-5 has-text-left has-text-weight-bold">
                          {{ this.thirdBestSearch.NrOfSearch }}
                          {{
                            $t(
                              'Components.Settings.Widgets.bookingsWidgetStats.Label_Searches'
                            )
                          }}
                        </p>
                        <p class="is-size-7 has-text-left is-italic">
                          {{
                            $t(
                              'Components.Settings.Widgets.bookingsWidgetStats.Label_On'
                            )
                          }}
                          <b>{{
                            this.thirdBestSearch.Date
                              | parseIsoDateStringToDate
                              | dateFormat($i18n.locale)
                          }}</b>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td class="tableCell">
                        <span class="icon fa-stack">
                          <font-awesome-icon
                            class="fa-stack-2x"
                            :icon="['far', 'square']"
                          />
                          <strong class="fa-stack fa-1x rankNumber"
                            ><b class="is-size-3"> 4 </b></strong
                          >
                        </span>
                      </td>
                      <td>
                        <p class="is-size-5 has-text-left has-text-weight-bold">
                          {{ this.fourthBestSearch.NrOfSearch }}
                          {{
                            $t(
                              'Components.Settings.Widgets.bookingsWidgetStats.Label_Searches'
                            )
                          }}
                        </p>
                        <p class="is-size-7 has-text-left is-italic">
                          {{
                            $t(
                              'Components.Settings.Widgets.bookingsWidgetStats.Label_On'
                            )
                          }}
                          <b>{{
                            this.fourthBestSearch.Date
                              | parseIsoDateStringToDate
                              | dateFormat($i18n.locale)
                          }}</b>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td class="tableCell">
                        <span class="icon fa-stack">
                          <font-awesome-icon
                            class="fa-stack-2x"
                            :icon="['far', 'square']"
                          />
                          <strong class="fa-stack fa-1x rankNumber"
                            ><b class="is-size-3"> 5 </b></strong
                          >
                        </span>
                      </td>
                      <td>
                        <p class="is-size-5 has-text-left has-text-weight-bold">
                          {{ this.fifthBestSearch.NrOfSearch }}
                          {{
                            $t(
                              'Components.Settings.Widgets.bookingsWidgetStats.Label_Searches'
                            )
                          }}
                        </p>
                        <p class="is-size-7 has-text-left is-italic">
                          {{
                            $t(
                              'Components.Settings.Widgets.bookingsWidgetStats.Label_On'
                            )
                          }}
                          <b>{{
                            this.fifthBestSearch.Date
                              | parseIsoDateStringToDate
                              | dateFormat($i18n.locale)
                          }}</b>
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div class="column is-one-thirds">
            <ui-section-header>
              <template v-slot:title>
                {{
                  $t(
                    'Components.Settings.Widgets.bookingsWidgetStats.Title_TotalSearchesAndReservations'
                  )
                }}
              </template>
            </ui-section-header>
            <div
              class="is-italic has-margin-top has-text-centered has-margin-bottom"
            >
              <ul>
                <li>
                  {{
                    $t(
                      'Components.Settings.Widgets.bookingsWidgetStats.Label_From'
                    )
                  }}
                  <strong>{{
                    startDate
                      | dateTimeStampToDate
                      | dateObjectIsoDateString
                      | dateFormat($i18n.locale)
                  }}</strong>
                </li>
                <li>
                  {{
                    $t(
                      'Components.Settings.Widgets.bookingsWidgetStats.Label_To'
                    )
                  }}
                  <strong>{{
                    endDate
                      | dateTimeStampToDate
                      | dateObjectIsoDateString
                      | dateFormat($i18n.locale)
                  }}</strong>
                </li>
              </ul>
            </div>

            <!-- <ui-loader v-if="isSearching" /> -->

            <div
              v-if="
                this.bestSearch.NrOfSearch === 0 &&
                this.bestReservation.NrOfReservationCreated === 0
              "
            >
              <article class="message is-warning has-margin-top">
                <div class="message-body">
                  {{
                    $t(
                      'Components.Settings.Widgets.bookingsWidgetStats.Message_NothingToShow'
                    )
                  }}
                </div>
              </article>
            </div>

            <div v-else>
              <PieChart
                :chartdata="pieChartDataset"
                :options="pieChartOptions"
              />
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import statsProvider from '@/providers/stats'
import Message from '@/components/UI/Message'
import BarChart from '@/components/UI/Charts/BarChart'
import PieChart from '@/components/UI/Charts/PieChart'
import { EventBus } from '@/eventbus/event-bus'

export default {
  components: {
    Message,
    BarChart,
    PieChart,
  },

  data() {
    return {
      isLoading: false,
      isSearching: false,
      widgets: [],
      widgetId: Number(this.$route.params.widgetId),
      channelId: Number(this.$route.params.channelId),
      locationId: 0,
      startDate: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000),
      endDate: new Date(),
      NrOfSearch: [],
      Dates: [],
      NrOfReservationCreated: [],
      polarChartColors: [],
      bestSearch: {
        NrOfReservationCreated: null,
        Date: null,
        NrOfSearch: null,
      },
      secondBestSearch: {
        NrOfReservationCreated: null,
        Date: null,
        NrOfSearch: null,
      },
      thirdBestSearch: {
        NrOfReservationCreated: null,
        Date: null,
        NrOfSearch: null,
      },
      fourthBestSearch: {
        NrOfReservationCreated: null,
        Date: null,
        NrOfSearch: null,
      },
      fifthBestSearch: {
        NrOfReservationCreated: null,
        Date: null,
        NrOfSearch: null,
      },
      bestReservation: {
        NrOfReservationCreated: null,
        Date: null,
        NrOfSearch: null,
      },
      secondBestReservation: {
        NrOfReservationCreated: null,
        Date: null,
        NrOfSearch: null,
      },
      thirdBestReservation: {
        NrOfReservationCreated: null,
        Date: null,
        NrOfSearch: null,
      },
      fourthBestReservation: {
        NrOfReservationCreated: null,
        Date: null,
        NrOfSearch: null,
      },
      fifthBestReservation: {
        NrOfReservationCreated: null,
        Date: null,
        NrOfSearch: null,
      },
      tempSearchStats: {},
      sortedSearchStats: {},
      tempReservationStats: {},
      stats: [],
      selectedMeetingtypeId: 0,
      embedCode: {
        onScreen: '',
        copyText: '',
      },

      barChartDataset: {
        labels: [],
        datasets: [
          {
            label: this.$i18n.t(
              'Components.Settings.Widgets.BarChartSearchWidgets.Label_NrOfReservations'
            ),
            borderWidth: 1,
            backgroundColor: '#95C11E',
            borderColor: '#95C11E',
            pointBorderColor: '#95C11E',
            data: [],
          },
          {
            label: this.$i18n.t(
              'Components.Settings.Widgets.BarChartSearchWidgets.Label_NrOfSearches'
            ),
            borderWidth: 1,
            backgroundColor: '#00BAFF',
            borderColor: '#00BAFF',
            pointBorderColor: '#00BAFF',
            data: [],
          },
        ],
      },
      barChartOptions: {
        responsive: true,
        maintainAspectRatio: true,
        aspectRatio: 3 / 1,
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: false,
          },
        },
        plugins: {
          legend: {
            position: 'bottom',
          },
        },
      },

      pieChartDataset: {
        labels: [
          this.$i18n.t(
            'Components.Settings.Widgets.PieChartSearchWidgets.Label_NrOfReservations'
          ),
          this.$i18n.t(
            'Components.Settings.Widgets.PieChartSearchWidgets.Label_NrOfSearches'
          ),
        ],
        datasets: [
          {
            label: 'Total nr of searches',
            backgroundColor: ['#95C11E', '#00BAFF'],
            data: [0, 0],
          },
        ],
      },
      pieChartOptions: {
        responsive: true,
        maintainAspectRatio: true,
        aspectRatio: 16 / 9,
        plugins: {
          legend: {
            position: 'right',
          },
        },
      },
    }
  },

  computed: {
    ...mapState('channelStore', ['channel']),
    ...mapState('widgetStore', ['selectedWidget']),

    meetingtypes() {
      if (this.channel) {
        return this.channel.Meetingtypes
      } else {
        return []
      }
    },
  },

  created() {
    this.getTransactionActivity()

    this.generateEmbedCode()
  },

  methods: {
    getTransactionActivity() {
      let self = this
      self.isSearching = true
      self.stats = []
      let startDate = this.$options.filters.dateObjectIsoDateString(
        self.startDate
      )
      let endDate = this.$options.filters.dateObjectIsoDateString(self.endDate)

      statsProvider.methods
        .getTransactionActivity(
          startDate,
          endDate,
          this.channelId,
          this.locationId,
          this.widgetId,
          this.selectedMeetingtypeId
        )
        .then((response) => {
          self.isSearching = false

          //setting up stats
          self.stats = response.data

          self.tempSearchStats = response.data.slice(0)
          self.tempReservationStats = response.data.slice(0)

          //sorting and setting top 5 for best performing reservation days
          self.tempReservationStats.sort((a, b) => {
            return b.NrOfReservationCreated - a.NrOfReservationCreated
          })

          self.bestReservation = self.tempReservationStats[0]

          if (self.tempReservationStats.length <= 1) {
            self.secondBestReservation.NrOfReservationCreated = 0
            self.secondBestReservation.NrOfSearch = 0
            self.secondBestReservation.Date = new Date()
          } else {
            self.secondBestReservation = self.tempReservationStats[1]
          }

          if (self.tempReservationStats <= 2) {
            self.thirdBestReservation.NrOfReservationCreated = 0
            self.thirdBestReservation.NrOfSearch = 0
            self.thirdBestReservation.Date = new Date()
          } else {
            self.thirdBestReservation = self.tempReservationStats[2]
          }

          if (self.tempReservationStats <= 3) {
            self.fourthBestReservation.NrOfReservationCreated = 0
            self.fourthdBestReservation.NrOfSearch = 0
            self.fourthBestReservation.Date = new Date()
          } else {
            self.fourthBestReservation = self.tempReservationStats[3]
          }

          if (self.tempReservationStats <= 4) {
            self.fifthBestReservation.NrOfReservationCreated = 0
            self.fifthBestReservation.NrOfSearch = 0
            self.fifthBestReservation.Date = new Date()
          } else {
            self.fifthBestReservation = self.tempReservationStats[4]
          }

          //sorting and setting top 5 for best performing search days
          self.tempSearchStats.sort((a, b) => {
            return b.NrOfSearch - a.NrOfSearch
          })

          self.bestSearch = self.tempSearchStats[0]

          if (self.tempSearchStats.length <= 1) {
            self.secondBestSearch.NrOfSearch = 0
            self.secondBestSearch.NrOfReservationCreated = 0
            self.secondBestSearch.Date = new Date()
          } else {
            self.secondBestSearch = self.tempSearchStats[1]
          }

          if (self.tempSearchStats.length <= 2) {
            self.thirdBestSearch.NrOfSearch = 0
            self.thirdBestSearch.NrOfReservationCreated = 0
            self.thirdBestSearch.Date = new Date()
          } else {
            self.thirdBestSearch = self.tempSearchStats[2]
          }

          if (self.tempSearchStats.length <= 3) {
            self.fourthBestSearch.NrOfSearch = 0
            self.fourthBestSearch.NrOfReservationCreated = 0
            self.fourthBestSearch.Date = new Date()
          } else {
            self.fourthBestSearch = self.tempSearchStats[3]
          }

          if (self.tempSearchStats.length <= 4) {
            self.fifthBestSearch.NrOfSearch = 0
            self.fifthBestSearch.NrOfReservationCreated = 0
            self.fifthBestSearch.Date = new Date()
          } else {
            self.fifthBestSearch = self.tempSearchStats[4]
          }

          //calculating totals
          self.Dates = []
          let nrOfReservationCreated = []
          let nrOfSearch = []
          let totalNrOfSearch = 0
          let totalNrOfReservationCreated = 0

          // creating different arrays for search, reservations and days
          let i = self.stats.length

          for (i = 0; i < self.stats.length; i++) {
            self.Dates.push(
              this.$options.filters.dateFormat(
                self.stats[i].Date,
                this.$i18n.locale,
                'shortDateFormat'
              )
            )
            nrOfReservationCreated.push(self.stats[i].NrOfReservationCreated)
            nrOfSearch.push(self.stats[i].NrOfSearch)
          }

          // total sum for searches and reservations made
          totalNrOfSearch = nrOfSearch.reduce((a, b) => a + b, 0)
          totalNrOfReservationCreated = nrOfReservationCreated.reduce(
            (a, b) => a + b,
            0
          )

          self.pieChartDataset.datasets[0].data = [
            totalNrOfReservationCreated,
            totalNrOfSearch,
          ]

          // Bar data Total searches and reservations
          let barChartDataset = this.$objectHelper.cleanSource(
            self.barChartDataset
          )
          barChartDataset.labels = self.Dates
          barChartDataset.datasets[0].data = nrOfReservationCreated
          barChartDataset.datasets[1].data = nrOfSearch
          self.barChartDataset = barChartDataset
        })
        .catch((e) => {})
        .finally(() => {
          self.isSearching = false
        })
    },

    setSelectedMeetingtypeId(meetingtypeId) {
      let self = this

      self.selectedMeetingtypeId = meetingtypeId
      self.getTransactionActivity()
    },

    datesChanged(searchQuery) {
      let self = this
      self.startDate = null
      self.endDate = null

      self.startDate = searchQuery.startDate
      self.endDate = searchQuery.endDate
      self.getTransactionActivity()
    },

    goToWidgetList() {
      this.$router.push({
        name: 'services-widgets',
      })
    },

    goToWidgetSettings() {
      this.$router.push({
        name: 'services-widgets-details-edit',
        params: { widgetId: this.selectedWidget.Id },
      })
    },

    copyWidgetCode() {
      navigator.clipboard
        .writeText(this.embedCode.copyText)
        .then(() => {
          EventBus.$emit('showToast', {
            type: 'info',
            message: 'Embed code copied to clipboard!',
          })
        })
        .catch((error) => {
          EventBus.$emit('showToast', {
            type: 'info',
            message: 'Oops, something went wrong here...',
          })
        })
    },

    generateEmbedCode() {
      let result =
        '<link href="https://widget.cyberdigma.nl/app.css" rel="stylesheet" /> #br#' +
        '<c_lab-bw id="c_lab-bw" widget-key="' +
        this.selectedWidget.Key +
        '" locale="nl" /> #br#' +
        '<script src="https://widget.cyberdigma.nl/app.js"></' +
        'script>'

      this.embedCode = {
        onScreen: this.encodeHtml(result).replace(/#br#/g, '</br>'),
        copyText: result.replace(/#br#/g, '\n'),
      }
    },

    encodeHtml(txt) {
      let divElm = document.createElement('div')
      divElm.innerText = txt
      return divElm.innerHTML
    },
  },
}
</script>

<style>
.embedCode {
  max-height: 200 px;
  /* font-size: 16px; */
}

.embedCodeText {
  margin-left: 0.01vw;
  font-size: 0.68vw;
}

.barChart {
  max-height: 300 px;
}

.tableCell {
  width: 13%;
}

.rankNumber {
  margin-top: 3px;
  margin-bottom: 4px;
  margin-left: 22px;
  margin-right: 10px;
}
</style>
