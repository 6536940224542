<template>
  <div>
    <ui-page-hero
      id="id_title_widget_settings"
      title="Bookings widget"
    ></ui-page-hero>
    <nav class="navbar level" key="back">
      <a @click="goToWidgetList()" class="navbar-item level-left">
        <span class="icon is-small">
          <font-awesome-icon :icon="['fas', 'chevron-left']" />
        </span>
        <span v-text="$t('Form.Control.Back')"></span>
      </a>
    </nav>

    <div class="tabs has-margin-top has-text-centered">
      <ul>
        <li :class="{ 'is-active': this.showStats === true }">
          <a @click="setShowStats()">
            <span>{{ $t('Views.Settings.Widget.Tab_Statistics') }}</span>
          </a>
        </li>
        <li :class="{ 'is-active': this.showEmbed === true }">
          <a @click="setShowEmbed()">
            <span>{{ $t('Views.Settings.Widget.Tab_Embedding') }}</span>
          </a>
        </li>
        <li :class="{ 'is-active': this.showStyling === true }">
          <a @click="setShowStyling()">
            <span>{{ $t('Views.Settings.Widget.Tab_Settings') }}</span>
          </a>
        </li>
      </ul>
    </div>

    <div v-if="showStats === true">
      <BookingWidgetStats />
    </div>

    <div v-if="showEmbed === true">
      <BookingWidgetEmbed />
    </div>

    <div v-if="showStyling === true">
      <BookingWidgetStyling />
    </div>
  </div>
</template>

<script>
import BookingWidgetStats from '@/components/Services/Widgets/BookingWidgetStats'
import BookingWidgetStyling from '@/components/Services/Widgets/BookingWidgetStyling'
import BookingWidgetEmbed from '@/components/Services/Widgets/BookingWidgetEmbed'

export default {
  components: {
    BookingWidgetStats,
    BookingWidgetEmbed,
    BookingWidgetStyling,
  },

  props: {
    showStatsProp: {
      type: Boolean,
    },
    showEmbedProp: {
      type: Boolean,
    },
    showStylingProp: {
      type: Boolean,
    },
  },

  created() {
    this.setComponent()
  },

  data() {
    return {
      showStats: Boolean,
      showEmbed: Boolean,
      showStyling: Boolean,
    }
  },

  methods: {
    setComponent() {
      let self = this

      self.showStats = this.showStatsProp
      self.showEmbed = self.showEmbedProp
      self.showStyling = self.showStylingProp
    },

    goToWidgetList() {
      this.$router.push({
        name: 'services-widgets',
      })
    },

    setShowStats() {
      this.showStats = true
      this.showEmbed = false
      this.showStyling = false
    },

    setShowEmbed() {
      this.showStats = false
      this.showEmbed = true
      this.showStyling = false
    },

    setShowStyling() {
      this.showStats = false
      this.showEmbed = false
      this.showStyling = true
    },
  },
}
</script>

<style></style>
