import axios from 'axios'
import store from '@/store'

export default {
  methods: {
    async getWidgetState(widgetKey) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/widget/search/${widgetKey}/state`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async getChannelSearchWidgets(channelId) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/widget/search/channel/${channelId}`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async getLocationSearchWidgets(locationId) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/widget/search/location/${locationId}`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async createLocationSearchWidgets(widget) {
      const response = await axios.post(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/widget/search`,
        JSON.stringify(widget),
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async deleteLocationSearchWidgets(widget) {
      const response = await axios.delete(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/widget/search/${widget.Id}`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async updateLocationSearchWidgets(widget) {
      const response = await axios.put(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/widget/search/${widget.Id}`,
        JSON.stringify(widget),
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },
  }
}