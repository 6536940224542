<template>
  <div>
    <canvas ref="chartWrapper"></canvas>
  </div>
</template>

<script>
import {
  Chart,
  BarElement,
  BarController,
  CategoryScale,
  LinearScale,
  Decimation,
  Legend,
  Title,
  Tooltip,
} from 'chart.js'
Chart.register(
  BarElement,
  BarController,
  CategoryScale,
  LinearScale,
  Decimation,
  Legend,
  Title,
  Tooltip
)

export default {
  props: {
    chartdata: {
      type: Object,
      default: null,
    },
    options: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      chartBar: null,
    }
  },

  watch: {
    chartdata: {
      deep: true,
      handler: function (val) {
        this.chartBar.data = val
        this.chartBar.update()
      },
    },
  },

  mounted() {
    this.chartBar = new Chart(this.$refs['chartWrapper'], {
      type: 'bar',
      data: this.chartdata,
      options: this.options,
    })
  },

  beforeDestroy() {
    this.chartBar.destroy()
  },

  methods: {},
}
</script>

<style lang="scss" scoped>
canvas {
  width: 100% !important;
}
</style>
