<template>
  <div>
    <div v-if="selectedWidget === null">
      <ui-loader />
    </div>
    <div v-else class="columns is-multiline mt-6">
      <div class="column is-one-quarter">
        <div class="card is-shadowless">
          <header class="card-header is-shadowless">
            <p class="card-header-title is-size-5 is-centered"></p>
          </header>
          <div class="card-content infotext">
            <p class="is-size-5 has-text-weight-bold">
              {{
                $t(
                  'Components.Settings.Widgets.bookingsWidgetEmbed.Label_EmbeddingCodeSubtitle'
                )
              }}
            </p>
            <p class="mt-4">
              {{
                $t(
                  'Components.Settings.Widgets.bookingsWidgetEmbed.Label_EmbeddingCodeText'
                )
              }}
            </p>
          </div>
        </div>
      </div>
      <div class="column is-two-thirds">
        <div class="card is-shadowless">
          <header class="card-header is-shadowless">
            <p class="card-header-title is-centered">
              {{ selectedWidget.Name }}
              {{
                $t(
                  'Components.Settings.Widgets.bookingsWidgetEmbed.Label_EmbeddingCode'
                )
              }}
            </p>
          </header>
          <div class="card-content">
            <div v-if="selectedWidget === null">
              <ui-loader />
            </div>
            <div
              v-else
              class="highlight has-text-justified has-background-light"
            >
              <pre>
              <code>
                <div v-html="embedCode.onScreen">         
                </div>
              </code>
            </pre>
            </div>
          </div>
          <footer class="card-footer buttons is-centered">
            <button
              class="button is-info text-white"
              @click.stop.prevent="copyWidgetCode()"
            >
              {{
                $t(
                  'Components.Settings.Widgets.bookingsWidgetEmbed.Button_CopyToClipboard'
                )
              }}
            </button>
          </footer>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import widgetProvider from '@/providers/widget'
import { EventBus } from '@/eventbus/event-bus'

export default {
  components: {},

  data() {
    return {
      isLoading: false,
      isSearching: false,
      widgetId: Number(this.$route.params.widgetId),
      channelId: Number(this.$route.params.channelId),
      locationId: 0,
      embedCode: {
        onScreen: '',
        copyText: '',
      },
    }
  },

  computed: {
    ...mapState('channelStore', ['channel']),
    ...mapState('widgetStore', ['selectedWidget']),
  },

  created() {
    this.generateEmbedCode()
  },

  methods: {
    copyWidgetCode() {
      navigator.clipboard
        .writeText(this.embedCode.copyText)
        .then(() => {
          EventBus.$emit('showToast', {
            type: 'info',
            message: this.$i18n.t(
              'Components.Settings.Widgets.bookingsWidgetEmbed.Message_EmbedCodeCopied'
            ),
          })
        })
        .catch((error) => {
          EventBus.$emit('showToast', {
            type: 'info',
            message: this.$i18n.t(
              'Components.Settings.Widgets.bookingsWidgetEmbed.Message_Error'
            ),
          })
        })
    },

    generateEmbedCode() {
      let result =
        '<link href="https://widget.cyberdigma.nl/booking/app.css" rel="stylesheet" /> #br#' +
        '<c-lab-bookingwidget widget-key="' +
        this.selectedWidget.Key +
        '" locale="nl"></c-lab-bookingwidget> #br#' +
        '<script src="https://widget.cyberdigma.nl/booking/app.js" async defer></' +
        'script>'

      this.embedCode = {
        onScreen: this.encodeHtml(result).replace(/#br#/g, '</br>'),
        copyText: result.replace(/#br#/g, '\n'),
      }
    },

    encodeHtml(txt) {
      let divElm = document.createElement('div')
      divElm.innerText = txt
      return divElm.innerHTML
    },
  },
}
</script>

<style lang="scss" scoped>
.card {
  .card-header {
    height: 50px;
  }
  .card-content {
    padding: 0px !important;
  }
  .card-footer {
    padding: 10px !important;
    border-top: hidden;
  }
}
</style>
