<template>
  <div class="has-margin-bottom" id="generalWidgetBackGround" :style="cssVars">
      <div class="row-navigation">
        <div class="SearchContext radius-top" id="headerBackGround" :style="cssVars">
        <div class="SearchContext__row radius-top has-margin-bottom level">
          <a class="MainControls__back is-outlined button has-margin-left has-margin-top radius" id="headerBackButton" :style="cssVars">
            <span class="icon is-small is-inline" >
              <font-awesome-icon :icon="['fas', 'chevron-left']" />
            </span>
            <span class="level-left">Terug
            </span>
          </a>
          <button class="button is-outlined level-right has-margin-right has-margin-top radius" id="headerExitButton" :style="cssVars">
            <span >Sluiten
            </span>
          </button>
        </div>
          <div class="is-hidden-mobile SearchContext__row" >
            <nav aria-label="breadcrumbs" class="has-arrow-separator breadcrumb has-margin-left" id="navArrows" :style="cssVars">
              <ul>
                <li class="is-completed is-primary">
                  <a class="" id="navCompleted" :style="cssVars">Locatie</a>
                </li>
                <li class="is-current">
                  <a class="has-text-weight-bold" id="navCurrent" :style="cssVars">Zalen</a>
                </li>
                <li class="is-active">
                  <a id="navInactive" :style="cssVars">Opties</a>
                </li>
                <li class="is-active">
                  <a id="navInactive" :style="cssVars">Boekingsgegevens</a>
                </li>
                <li class="is-active">
                  <a id="navInactive" :style="cssVars">Contact informatie</a>
                </li>
                <li class="is-active">
                  <a id="navInactive" :style="cssVars">Bevestiging</a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    <div class="filters has-margin-bottom-x2 has-margin-left">
      <div class="level-filters has-margin-bottom-half level is-mobile">
        <div class="level-left has-padding-right">
          <div class="is-size-4 has-text-weight-bold has-margin-top-x2" id="generalWidgetTitle" :style="cssVars" >Available rooms at this location
          </div>
        </div>
        <div class="is-hidden-tablet level-right">
          <a class="is-hidden-tablet button is-small">
            <span class="icon">
              <font-awesome-icon :icon="[ 'far', 'sliders-h']" />
            </span>
            <span>Filter</span>
          </a>
        </div>
      </div>
      <div class="is-inline-flex is-hidden-mobile SearchControls__filter-wrapper">
      <span class="icon">
        <font-awesome-icon :icon="[ 'far', 'sliders-h']" />
      </span>
      <div class="has-margin-right-half is-small">
        <select id="widgetInput" :style="cssVars" class="radius">
          <option value="0">Laat alle opstelling mogelijkheden zien</option>
          <option value="16">SitDown (2 spaces)</option>
          <option value="10">Meeting (6 spaces)</option>
          <option value="3">Carre (2 spaces)</option>
          <option value="4">CreatieveSetting (4 spaces)</option>
          <option value="9">UShape (1 spaces)</option>
          <option value="25">25 (3 spaces)</option>
        </select>
      </div>
      <div class="is-small has-margin-left">
        <select id="widgetInput" :style="cssVars" class="radius">
          <option 
          value="0">Laat alle event type mogelijkheden zien</option>
          <option value="1">Meeting </option>
          <option value="2">Training </option>
          <option value="3">Brainstorm session </option>
          <option value="4">Event </option>
          <option value="5">Reception </option>
          <option value="6">Network lunch </option>
          <option value="7">Product launch </option>
          <option value="8">Presentation </option>
        </select>
      </div>
    </div>
  </div>
  <div class="columns is-multiline">
    <div class="column LocationSpaces ResultList mb-0 ml-1">
    <div class="LocationSpaceMS box radius" id="optionCardBackGround" :style="cssVars">
      <div class="columns">
        <figure class="room-image has-margin-top is-square has-ratio">
          <img 
          src="@/assets/images/WidgetRoomMockup1.jpg"
          alt="MeetingSpace1" 
          class="radius is-square has-ratio"
          />
        </figure>
      <div 
      class="LocationSpaceMS__detail_price column">
        <div class="LocationSpaceMS__details has-padding">
          <div class="LocationSpaceMS__title_size level" id="optionCardTitle" :style="cssVars">
            <div class="spaceName is-size-3 has-text-weight-bold">1. Option 1
            </div>
              <span class="tag level-right radius" id="optionCardFootageLabel" :style="cssVars">12m2
              </span>
          </div>
            <div class="LocationSpaceMS__price" id="optionCardPricing" :style="cssVars">
              <span 
              alt="Per persoon per uur" 
              title="Per persoon per uur">
                <strong class="has-text-weight-bold is-size-5" id="optionCardPricing" :style="cssVars">€&nbsp;22,-
                </strong> p.p.
              </span>
                <div class="LocationSpaceMS__priceExplanation is-size-7" id="optionCardPricing" :style="cssVars">€&nbsp;88,- total
                </div>
              </div>
            </div>
            <div class="has-padding radius" id="optionCardSeatingBackGround" :style="cssVars">
              <div>
              <div class="has-margin-bottom has-text-weight-bold" id="optionCardSeatingArrangement" :style="cssVars"> Select a setting 
              </div>
                <div class="LocationSpaceMS__settings-book">
                  <div class="LocationSpaceMS__settings">
                    <a class="LocationSpaceMS__settings__setting is-inline-flex has-text-info has-text-centered box radius has-margin-right" id="buttonHover" :style="cssVars">
                      <ul>
                      <span  class="icon is-medium" aria-hidden="true">
                      <SvgSetting
                        aria-hidden="true"
                        :settingId="5"
                        size="is-medium"
                      /></span> 
                      <div 
                      class="LocationSpaceMS__settings__setting__name is-size-7">Sit-down
                      </div>
                      </ul>
                    </a>
                    <a class="LocationSpaceMS__settings__setting is-inline-flex has-text-info has-text-centered box radius has-margin-right" id="buttonHover" :style="cssVars">
                      <ul>
                      <span class="icon is-medium" aria-hidden="true">
                        <SvgSetting
                        aria-hidden="true"
                        :settingId="8"
                        size="is-medium"
                      /></span>
                      <div 
                      class="LocationSpaceMS__settings__setting__name is-size-7">Sit-down
                      </div>
                      </ul>
                    </a>
                    <a class="LocationSpaceMS__settings__setting is-inline-flex has-text-info has-text-centered box radius has-margin-right" id="buttonHover" :style="cssVars">
                      <ul>
                      <span  class="icon is-medium" aria-hidden="true"><SvgSetting
                        aria-hidden="true"
                        :settingId="10"
                        size="is-medium"
                      /></span>
                      <div 
                      class="LocationSpaceMS__settings__setting__name is-size-7">Sit-down
                      </div>
                      </ul>
                    </a>
                  </div>
                  <div class="LocationSpaceMS__button level-right">
                    <button class="button radius" id="buttonActive" :style="cssVars">Book
                    </button>
                  </div>
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>

    <div class="LocationSpaceMS box radius" id="optionCardBackGround" :style="cssVars">
      <div class="columns is-multiline">
        <figure class="has-margin-top room-image is-square has-ratio">
          <img 
          src="@/assets/images/WidgetRoomMockup2.jpg"
          alt="MeetingSpace2" 
          class="radius"
          />
        </figure>
      <div 
      class="LocationSpaceMS__detail_price column">
        <div class="LocationSpaceMS__details has-padding">
          <div class="LocationSpaceMS__title_size level" id="optionCardTitle" :style="cssVars">
            <div class="spaceName is-size-3 has-text-weight-bold">2. Option 2
            </div>
              <span class="tag level-right radius" id="optionCardFootageLabel" :style="cssVars">20m2
              </span>
          </div>
            <div class="LocationSpaceMS__price" id="optionCardPricing" :style="cssVars">
              <span 
              alt="Per persoon per uur" 
              title="Per persoon per uur">
                <strong class="has-text-weight-bold is-size-5" id="optionCardPricing" :style="cssVars">€&nbsp;52,-
                </strong> p.p.
              </span>
                <div class="LocationSpaceMS__priceExplanation is-size-7" id="optionCardPricing" :style="cssVars">€&nbsp;88,- total
                </div>
              </div>
            </div>
            <div class="has-padding radius" id="optionCardSeatingBackGround" :style="cssVars">
              <div class="has-margin-bottom has-text-weight-bold" id="optionCardSeatingArrangement" :style="cssVars"> Select a setting 
              </div>
                <div class="LocationSpaceMS__settings-book">
                  <div class="LocationSpaceMS__settings">
                    <a class="LocationSpaceMS__settings__setting is-inline-flex has-text-info has-text-centered box radius has-margin-right" id="buttonHover" :style="cssVars">
                      <ul>
                      <span  class="icon is-medium" aria-hidden="true">
                      <SvgSetting
                        aria-hidden="true"
                        :settingId="2"
                        size="is-medium"
                      /></span> 
                      <div 
                      class="LocationSpaceMS__settings__setting__name is-size-7">Sit-down
                      </div>
                      </ul>
                    </a>
                    
                    <a class="LocationSpaceMS__settings__setting is-inline-flex has-text-info has-text-centered box radius has-margin-right is-multiline" id="buttonHover" :style="cssVars">
                      <ul><span class="icon is-medium" aria-hidden="true">
                        <SvgSetting
                        aria-hidden="true"
                        :settingId="4"
                        size="is-medium"
                      /></span>
                      <div 
                      class="LocationSpaceMS__settings__setting__name is-size-7">Sit-down
                      </div>
                      </ul>
                    </a>
                    <a class="LocationSpaceMS__settings__setting is-inline-flex has-text-info has-text-centered box radius has-margin-right" id="buttonHover" :style="cssVars">
                      <ul>
                        <span  class="icon is-medium" aria-hidden="true"><SvgSetting
                        aria-hidden="true"
                        :settingId="11"
                        size="is-medium"
                      /></span>
                      <div 
                      class="LocationSpaceMS__settings__setting__name is-size-7">Sit-down
                      </div>
                      </ul>
                    </a>
                  </div>
                  <div class="LocationSpaceMS__button level-right">
                    <button disabled="disabled" class="button radius" id="disabledButton" :style="cssVars">Book
                    </button>
                  </div>
                </div>
              </div>
            </div>
            </div>
          </div>
          </div>

        <div class="column is-hidden-mobile column-sidebar is-one-third mr-1">

          <div class="LocationMap ">
            <div class="jw-map-wrapper MapBox">
              <img 
                src="@/assets/images/widgetmapmockup.png"
                alt="map" 
                class="radius map-image"
                />
            </div>
          </div>

          <article 
          class="has-margin-top has-padding radius box has-margin-bottom is-hidden-mobile column-sidebar" id="contactCardBackGround" :style="cssVars">
            <div 
            class="">
              <div 
              class="level">
                <figure >
                  <p 
                  class="image is-64x64 has-margin-right level-left">
                    <img 
                    src="data:image/gif;base64,R0lGODlhAQABAIAAAP///////yH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" 
                    alt="Contact image" 
                    class="has-shadow radius" 
                    style="background-image: url(&quot;https://az691754.vo.msecnd.net/website-staging/85/160x160_7d55d759-8e5d-482a-b93b-cba6af45efee.jpg&quot;); filter:blur(1px)">
                    </p>
                    </figure>
                    <div>
                    <div id="contactCardMessage" :style="cssVars" class="level-right">Hi there! Please let us know if you need anything.
                    </div>
                  </div>
                </div>
                <div 
                class="level-item">
                <a 
                href="tel:+01234567890" 
                class="has-icon" id="contactCardPhone" :style="cssVars">
                  <span class="icon">
                    <font-awesome-icon :icon="['far', 'phone-alt']" />
                  </span>
                  <span>+01234567890
                  </span>
                </a>
              </div>
            </div>
          </article>

          <div 
          class="LocationCard LocationCard--selected LocationCard--horizontal box radius has-margin-bottom" id="upsellCardBackground" :style="cssVars" >
            <div 
            class="LocationCard__detail_price">
              <div 
              class="LocationCard__details">

              <div class="column" >
                <div 
                class="has-text-weight-bold is-size-4" id="upsellCardTitle" :style="cssVars">This location
                </div>
                  <p 
                  class="has-margin-bottom" id="upsellCardSubTitle" :style="cssVars">
                    <span 
                    class="has-icon">
                      <span 
                      class="icon">
                        <font-awesome-icon :icon="['far', 'map-marker-alt']" />
                      </span>
                      <span
                      >Street name 123, City 
                      </span>
                    </span>
                  </p>
                  <div id="upselCardBody" :style="cssVars"
                  class="has-margin-bottom upsells">
                    <div  
                    class="upsells__upsell level-left">
                      <span  
                      class="icon">
                        <font-awesome-icon :icon="['far', 'check']" />
                      </span>
                      <div 
                      >Perfectly located
                      </div>
                    </div>
                    <div 
                    class="upsells__upsell level-left">
                      <span 
                      class="icon">
                        <font-awesome-icon :icon="['far', 'check']" />
                      </span>
                        <div >Free WIFI
                        </div>
                      </div>
                        <div 
                        class="upsells__upsell level-left">
                          <span 
                          class="icon">
                            <font-awesome-icon :icon="['far', 'check']" />
                          </span>
                            <div >Great community
                            </div>
                          </div>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
        </div>
  </div>
</div>
</template>

<script>
import SvgSetting from '@/components/UI/Settings/SvgSetting'

export default {
  
  components: {
    SvgSetting,
  },
  
  props: {
    HeaderBackground: {
      type: String,
      default: null,
    },
    GeneralBackground: {
      type: String,
      default: null,
    },
    OptionCardBackground: {
      type: String,
      default: null,
    },
    OptionCardSeatingBackground: {
      type: String,
      default: null,
    },
    ContactCardBackground: {
      type: String,
      default: null,
    },
    UpsellCardBackground: {
      type: String,
      default: null,
    },
    GeneralBorderColor: {
        type: String,
        default: null,
    },
    OptionCardTitle: {
      type: String,
      default: null,
    },
    OptionCardPricing: {
      type: String,
      default: null,
    },
    NavCompleted: {
      type: String,
      default: null,
    },
    NavInactive: {
      type: String,
      default: null
    },
    OptionCardSeatingArrangement: {
      type: String,
      default: null
    },
    GeneralTitleColor: {
      type: String,
      default: null
    },
    ContactCardMessage: {
      type: String,
      default: null
    },
    ContactCardPhone: {
      type: String,
      default: null
    },
    ButtonActive: {
      type: String,
      default: null
    },
    ButtonActiveBackground: {
      type: String,
      default: null
    },
    ButtonDisabledBackground: {
      type: String,
      default: null
    },
    ButtonDisabled: {
      type: String,
      default: null
    },
    ButtonHoverBackground: {
      type: String,
      default: null
    },
    OptionCardFootageLabelBackground: {
      type: String,
      default: null
    },
    BackButtonBackground: {
      type: String,
      default: null
    },
    ExitButtonBackground: {
      type: String,
      default: null
    },
    NavArrows: {
      type: String,
      default: null
    },
    GeneralRadius: {
      type: Number,
      default: null
    },
    NavButtonsBorderWidth: {
      type: Number,
      default: null
    },
    UpsellCardBody: {
      type: String,
      default: null
    },
    UpsellCardSubTitle: {
      type: String,
      default: null
    },
    UpsellCardTitle: {
      type: String,
      default: null
    },
    OptionCardFootageLabel: {
      type: String,
      default: null
    },
    // UpsellCardIcon: {
    //   type: String,
    //   default: null
    // },
    InputBackground: {
      type: String,
      default: null
    },
    InputText: {
      type: String,
      default: null
    },
    ButtonBorderWidth: {
      type: Number,
      default: null
    },
    InputBorderWidth: {
      type: Number,
      default: null
    },
    BackButton: {
      type: String,
      default: null
    },
    ExitButton: {
      type: String,
      default: null
    },
    NavCurrent: {
      type: String,
      default: null
    },
    OptionCardArrangementIconBackground: {
      type: String,
      default: null
    },
  },

  computed: {
      cssVars () {
        return{
          /* variables we want to pass to css */

          //General styling //
          '--GeneralRadius': (this.GeneralRadius * 1) + "px",
          '--GeneralBorderColor': this.GeneralBorderColor,
          '--GeneralBackground': this.GeneralBackground,
          '--GeneralTitleColor': this.GeneralTitleColor,

          //Header styling//
          '--HeaderBackground': this.HeaderBackground,
          '--BackButton': this.BackButton,
          '--ExitButton': this.ExitButton,
          '--BackButtonBackground': this.BackButtonBackground,
          '--ExitButtonBackground': this.ExitButtonBackground,
          '--NavCompleted': this.NavCompleted,
          '--NavCurrent': this.NavCurrent,
          '--NavInactive': this.NavInactive,
          '--NavArrows': this.NavArrows,
          '--NavButtonsBorderWidth': (this.NavButtonsBorderWidth * 1) + "px",

          //Option Card styling//
          '--OptionCardBackground': this.OptionCardBackground,
          '--OptionCardSeatingBackground': this.OptionCardSeatingBackground,
          '--OptionCardArrangementIconBackground': this.OptionCardArrangementIconBackground,
          '--OptionCardFootageLabelBackground': this.OptionCardFootageLabelBackground,
          '--OptionCardFootageLabel': this.OptionCardFootageLabel,
          '--OptionCardSeatingArrangement': this.OptionCardSeatingArrangement,
          '--OptionCardPricing': this.OptionCardPricing,
          '--OptionCardTitle': this.OptionCardTitle,

          //Contact Card styling//
          '--ContactCardBackground': this.ContactCardBackground,
          '--ContactCardMessage': this.ContactCardMessage,
          '--ContactCardPhone': this.ContactCardPhone,

          //Upsell Card styling//
          '--UpsellCardBackground': this.UpsellCardBackground,
          '--UpsellCardTitle': this.UpsellCardTitle,
          '--UpsellCardSubTitle': this.UpsellCardSubTitle,
          '--UpsellCardBody': this.UpsellCardBody,
          // '--UpsellCardIcon': this.UpsellCardIcon,

          //Input styling//
          '--InputText': this.InputText,
          '--InputBackground': this.InputBackground,
          '--InputBorderWidth': (this.InputBorderWidth * 1) + "px",

          //Button styling//
          '--ButtonActive': this.ButtonActive,
          '--ButtonActiveBackground': this.ButtonActiveBackground,
          '--ButtonDisabled': this.ButtonDisabled,
          '--ButtonDisabledBackground': this.ButtonDisabledBackground,
          '--ButtonHoverBackground': this.ButtonHoverBackground,
          '--ButtonBorderWidth': (this.ButtonBorderWidth * 1) + "px",

        }
    }
  },
}
</script>

<style lang="scss" scoped>

// Dit nog verwerken in de disabled NavBar text //
.breadcrumb {
  li {
  &:before {color: var(--NavArrows)} 
  }
}
.radius {
  border-width: var(--NavButtonsBorderWidth);
  border-color: var(--GeneralBorderColor);
  border-radius: var(--GeneralRadius);
}
.radius-top {
  border-top-left-radius: var(--GeneralRadius);
  border-top-right-radius: var(--GeneralRadius);
}
.map-image {
  width: 100%;
  height: auto;
}
.room-image {
   width: 100%;
    max-height: 480px;
    // min-height: 256px;
    max-width: 480px;
    // min-width: 256px;
    background-size: cover;
    background-position: center;
}
#generalWidgetBackGround {
  background-color: var(--GeneralBackground);
  border-color: var(--GeneralBorderColor);
}
#headerBackGround {
  background-color: var(--HeaderBackground);
}

#optionCardBackGround {
  background-color: var(--OptionCardBackground);
  border-color: var(--GeneralBorderColor);
}
#optionCardSeatingBackGround {
  background-color: var(--OptionCardSeatingBackground);
}
#contactCardBackGround {
  background-color: var(--ContactCardBackground);
}
#upsellCardBackground {
  background-color: var(--UpsellCardBackground);
}
#optionCardFootageLabel {
  color: var(--OptionCardFootageLabel);
  background-color: var(--OptionCardFootageLabelBackground);
}
#widgetInput {
  color: var(--InputText);
  background-color: var(--InputBackground);
  border-width: var(--InputBorderWidth);
}
#headerBackButton {
  color: var(--BackButton);
  background-color: var(--BackButtonBackground);
}
#headerExitButton {
  color: var(--ExitButton);
  background-color: var(--ExitButtonBackground);
}
#optionCardTitle {
  color: var(--OptionCardTitle);
}
#optionCardPricing {
  color: var(--OptionCardPricing);
}
#navCompleted {
  color: var(--NavCompleted);
}
#navInactive {
  color: var(--NavInactive);
}
#optionCardSeatingArrangement {
  color: var(--OptionCardSeatingArrangement);
}
#generalWidgetTitle {
  color: var(--GeneralTitleColor);
}
#contactCardMessage {
  color: var(--ContactCardMessage);
}
#contactCardPhone {
  color: var(--ContactCardPhone);
}
#navArrows {
  color: var(--NavArrows);
}
#upselCardBody {
  color: var(--UpsellCardBody);
}
#upsellCardSubTitle {
  color: var(--UpsellCardSubTitle)
}
#upsellCardTitle {
  color: var(--UpsellCardTitle)
}
#navCurrent {
  color: var(--NavCurrent);
}
#buttonActive {
  &:hover {
    background-color: var(--ButtonHoverBackground) !important;
  }
  color: var(--ButtonActive);
  background-color: var(--ButtonActiveBackground);
  border-width: var(--ButtonBorderWidth);
}
#disabledButton {
  color: var(--ButtonDisabled);
  background-color: var(--ButtonDisabledBackground);
  border-width: var(--ButtonBorderWidth);
}
// #navCompletedc {
//   color: var(--UpsellCardIcon);
// }
#buttonHover {
  &:hover {
    background-color: var(--ButtonHoverBackground) !important;
  }
    background-color: var(--OptionCardArrangementIconBackground) !important;
}

</style>